import { Component } from "@angular/core";
import { AppMenuItemComponent } from "./app-menu-item/app-menu-item.component";
import { CommonModule } from "@angular/common";
import { Router, RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AuthApi } from "vegiwise-sdk-typescript/dist/sdk/auth/auth.api";

@Component({
  selector: "vw-app-menu",
  templateUrl: "./app-menu.component.html",
  styleUrls: ["./app-menu.component.scss"],
  standalone: true,
  providers: [AuthApi],
  imports: [AppMenuItemComponent, CommonModule, RouterModule, TranslateModule],
})
export class AppMenuComponent {
  constructor(
    private readonly authAPI: AuthApi,
    private readonly router: Router
  ) {}

  get fullName(): string {
    // TO DO get from userApi when it is ready
    return `Admin User`;
  }

  onLogout(): void {
    //this.authAPI.logout();
    this.router.navigate(["/auth/login"]);
  }
}
