<h2 class="uppercase font-bold text-center mb-2">New notification</h2>
<div>
    <!-- Criteria -->
    <div class="mb-6">
        <div class="flex justify-between py-1">
            <h3 class="font-bold text-mx uppercase">Criteria's</h3>
            <!--<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>-->
            <vw-rounded-button
                    [size] ="'XS'"
                    (click) = "createCriteria()"
                    [look]="'DEFAULT'">
                <svg class="btn-icon" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.75 12H16.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M12.5 8.25V15.75" stroke="white" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                </svg>
            </vw-rounded-button>
        </div>

        <!-- Ligne -->
        <div class="h-[1px] bg-[#E1E1E1] w-full my-2"></div>
        <!-- Criteria list -->
        <div>
            <!-- Criteria 1 / simple -->
            <div class="flex justify-between py-2">
                <p>
                    New User
                </p>
                <!-- EDIT - DELETE -->
                <div class="flex">
                    <!-- edit icon -->
                    <a href="#" class="mr-2">                    
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                        </svg>
                    </a>
                    <!-- delete icon -->
                    <a href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                    </a>
                </div>
            </div>
            <!-- Criteria 2 / simple -->
            <div class="py-2">
                <div class="h-[1px] bg-[#E1E1E1] w-full my-2"></div>
                <div class="flex justify-between py-2 items-center">
                    <div class="flex items-center">
                        <p class="mr-1">
                            Between
                        </p>
                        <div>
                            <!-- {{ min }} -->
                            <span class="p-2 mx-1 bg-gray-700 text-white text-[15px] leading-6 font-medium rounded-[9px] text-center" >20</span>
                            and
                            <!-- {{ max }} -->
                            <span class="p-2 mx-1 bg-gray-700 text-white text-[15px] leading-6 font-medium rounded-[9px] text-center" >100</span>
                        </div>
                    </div>
                    <!-- EDIT - DELETE -->
                    <div class="flex">
                        <!-- edit icon -->
                        <a href="#" class="mr-2">                    
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                            </svg>
                        </a>
                        <!-- delete icon -->
                        <a href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <!-- Criteria 3 / toggle true -->
            <div class="py-2">
                <div class="h-[1px] bg-[#E1E1E1] w-full my-2"></div>
                <div class="py-2">
                    <div class="flex justify-between items-center">
                        <div class="flex items-center">
                            <p class="mr-2">Criteria 3</p>
                            <div class="toggle">
                                <input type="checkbox" id="toggle" value="" class="sr-only" checked>
                                <label for="toggle" class="toggle-label"></label>
                            </div>
                        </div>
                        
                        <!-- EDIT - DELETE -->
                        <div class="flex">
                            <!-- edit icon -->
                            <a href="#" class="mr-2">                    
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                </svg>
                            </a>
                            <!-- delete icon -->
                            <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Criteria 4 / toggle false -->
            <div class="py-2">
                <div class="h-[1px] bg-[#E1E1E1] w-full my-2"></div>
                <div class="py-2">
                    <div class="flex justify-between items-center">
                        <div class="flex items-center">
                            <p class="mr-2">Criteria 4</p>
                            <div class="toggle">
                                <input type="checkbox" id="toggle" value="" class="sr-only">
                                <label for="toggle" class="toggle-label"></label>
                            </div>
                        </div>
                        
                        <!-- EDIT - DELETE -->
                        <div class="flex">
                            <!-- edit icon -->
                            <a href="#" class="mr-2">                    
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                </svg>
                            </a>
                            <!-- delete icon -->
                            <a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Details -->
    <div class="mb-4">
        <h2 class="uppercase font-bold">Details</h2>
        <div class="h-[1px] bg-[#E1E1E1] w-full my-2"></div>
        <!-- field form -->
        <div class="flex flex-col mb-4">
            <label for="title" class="font-bold">Title</label>
            <input name="title" id="title" rows="2" placeholder="Enter your title here" class="mt-2 px-3 py-2 bg-gray-100 border rounded-md w-full"/>
        </div>
        <!-- image field -->
        <div class="mb-2">
            <label for="file-input" class="relative cursor-pointer">
            <input type="file" id="file-input" class="hidden" accept="image/*">
            <div class="flex items-center justify-center border border-dashed rounded-md border-[#30A156] p-3">
                <svg class="w-6 h-6 mr-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.3334 5.59984C18.6647 5.59984 18.9334 5.33121 18.9334 4.99984C18.9334 4.66847 18.6647 4.39984 18.3334 4.39984V5.59984ZM11.6667 4.39984C11.3353 4.39984 11.0667 4.66847 11.0667 4.99984C11.0667 5.33121 11.3353 5.59984 11.6667 5.59984V4.39984ZM15.6 1.6665C15.6 1.33513 15.3314 1.0665 15 1.0665C14.6686 1.0665 14.4 1.33513 14.4 1.6665H15.6ZM14.4 8.33317C14.4 8.66454 14.6686 8.93317 15 8.93317C15.3314 8.93317 15.6 8.66454 15.6 8.33317H14.4ZM18.3334 4.39984H15V5.59984H18.3334V4.39984ZM15 4.39984H11.6667V5.59984H15V4.39984ZM14.4 1.6665V4.99984H15.6V1.6665H14.4ZM14.4 4.99984V8.33317H15.6V4.99984H14.4Z" fill="#30A156"/>
                    <path d="M9.58335 2.5C5.8514 2.5 3.98542 2.5 2.82606 3.65937C1.66669 4.81874 1.66669 6.68471 1.66669 10.4167C1.66669 14.1486 1.66669 16.0146 2.82606 17.174C3.98542 18.3333 5.8514 18.3333 9.58335 18.3333C13.3153 18.3333 15.1813 18.3333 16.3407 17.174C17.5 16.0146 17.5 14.1486 17.5 10.4167V10" stroke="#30A156" stroke-width="1.2" stroke-linecap="round"/>
                    <path d="M4.16669 17.4997C7.67501 13.5402 11.6177 8.28926 17.5 12.2275" stroke="#30A156" stroke-width="1.2"/>
                    </svg>
                <span class="text-[#30A156]">Upload cover picture</span>
            </div>
        </label>
        </div>
        <div class="flex flex-col mb-4">
            <label for="description" class="font-bold">Add a description</label>
            <textarea name="" id="" rows="4" placeholder="Your description" class="mt-2 px-3 py-2 bg-gray-100 border rounded-md w-full"></textarea>
        </div>
    </div>
    <!-- On open -->
    <div class="mb-4">
        <label for="description" class="block mb-2 uppercase font-bold">On open</label>
        <div class="h-[1px] bg-[#E1E1E1] w-full my-2"></div>
        <div class="flex-col justify-between">
            <!-- premier select -->
            <div class="relative mt-2">
                <select id="ext_file" class="p-2 bg-gray-100 border rounded-md w-full appearance-none">
                    <option selected>External link</option>
                    <option value="link1">Selected page</option>
                </select>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none w-6 h-6 text-gray-400">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>
            </div>
            <!-- Afficher l'un ou l'autre en fonction du select au dessus -->
            <!-- external link -->
            <div class="flex">
                <input name="url" id="url" rows="2" placeholder="Your external link" class="mt-2 px-3 py-2 bg-gray-100 border rounded-md w-full"/>
            </div>
            <!-- selected page -->
            <div class="relative mt-2">
                <select id="selected_page" class="p-2 bg-gray-100 border rounded-md w-full appearance-none">
                    <option value="page1" selected>Page 1</option>
                    <option value="page2">Page 2</option>
                    <option value="page3">Page 3</option>
                    <option value="page4">Page 4</option>
                </select>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none w-6 h-6 text-gray-400">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>
                <div class="text-right"></div>
            </div>
        </div>
    </div> 
    <div class="flex justify-between items-center px-2">
        <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
            </svg>
            <!-- a mettre {{ nbr de users filtrés }} à la place du 200 -->
            <p class="text-sm ">Your notification will reach 200 new users</p> 
        </div>
        <div>
            <input
                type="submit"
                value="Reach"
                class="py-[9px] px-4 bg-gray-700 text-white text-[15px] leading-6 font-medium rounded-[9px] mr-2"
            />
            <input
                type="submit"
                value="Post now"
                class="py-[9px] px-4 bg-[#30A156] text-white text-[15px] leading-6 font-medium rounded-[9px]"
            />
        </div>
    </div>
</div>
