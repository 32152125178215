import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DefaultLayoutComponent } from "../../@commons/layouts/default-layout/default-layout.component";
import { CultivationItemComponent } from "../../@commons/components/cultivation-item/cultivation-item.component";
import { Router, RouterModule } from "@angular/router";
import { CultivationApi } from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation.api";
import { StorageService } from "../../@commons/services/front/storage.service";
import { PagingResponse } from "../../@commons/services/base.service";
import { CultivationDto } from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import { ListCultivationRequest } from "vegiwise-sdk-typescript/dist/model/plant/list-cultivation-req";
import { ButtonComponent } from "../../@commons/components/@generic/buttons/button/button.component";
import { AbstractCultivationEditor } from "../cultivation-details/@abstract/abstract-cultivation-editor";
import { PlusIconComponent } from "../../@commons/components/@generic/icons/plus-icon/plus-icon.component";

@Component({
  selector: "vw-cultivation-list",
  standalone: true,
  imports: [
    CommonModule,
    DefaultLayoutComponent,
    CultivationItemComponent,
    RouterModule,
    ButtonComponent,
    PlusIconComponent,
  ],
  providers: [CultivationApi, StorageService],
  templateUrl: "./cultivation-list.component.html",
  styleUrls: ["./cultivation-list.component.scss"],
})
export class CultivationListComponent implements OnInit {
  cultivations: CultivationDto[] = [];
  page: 0;
  pageSize: 10;
  filters: ListCultivationRequest = new ListCultivationRequest();

  constructor(
    private router: Router,
    private cultivationApi: CultivationApi,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.loadCultivations();
  }

  loadCultivations() {
    this.cultivationApi
      .list(
        this.filters,
        this.page,
        this.pageSize,
        this.storageService.getCurrentLanguage()
      )
      .then((res: PagingResponse<CultivationDto>) => {
        console.log(res);
        this.cultivations.push(...res.results);
      });
  }

  goToCultivationDetails(cultivation: CultivationDto) {
    this.router.navigate(["cultivations/" + cultivation.uid]);
  }

  createNew($event: MouseEvent) {
    this.router.navigate(["cultivations/" + "create"]);
  }
}
