import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {RoundedButtonComponent} from "../buttons/rounded-button/rounded-button.component";
import {TrashIconComponent} from "../icons/trash-icon/trash-icon.component";
import {PreSignedUrlRes} from "vegiwise-sdk-typescript/dist/model/dms/pre-signed-url-res";
import {UploadFileRes} from "vegiwise-sdk-typescript/dist/model/dms/upload-file-res";
import {ExternalFileOrigin} from "vegiwise-sdk-typescript/dist/enums/external-file-origin.enum";

@Component({
  selector: 'vw-file-viewer',
  standalone: true,
  imports: [CommonModule, RoundedButtonComponent, TrashIconComponent],
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent {
  @Output() fileClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() backgroundColor: string;
  @Input() pictureUrl: string;
  @Input() selectedImage: File;
}
