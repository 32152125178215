import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-language-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './language-icon.component.html',
  styleUrls: ['./language-icon.component.scss']
})
export class LanguageIconComponent {

}
