import {Pipe, PipeTransform} from '@angular/core';
import {LabelDto} from "vegiwise-sdk-typescript/dist/model/@common/label";
@Pipe({
    name: 'Label',
    standalone: true
})
export class LabelPipe implements PipeTransform {
    transform(value: LabelDto, exponent = 1): string {
        return value ? value.fr: '';
    }
}
