import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  RoundedButtonComponent
} from "../../../@commons/components/@generic/buttons/rounded-button/rounded-button.component";
import {PlusIconComponent} from "../../../@commons/components/@generic/icons/plus-icon/plus-icon.component";

@Component({
  selector: 'vw-notification-creator',
  standalone: true,
    imports: [CommonModule, RoundedButtonComponent, PlusIconComponent],
  templateUrl: './notification-creator.component.html',
  styleUrls: ['./notification-creator.component.scss']
})
export class NotificationCreatorComponent {

  createCriteria(){

  }
}
