import { Component, OnInit } from '@angular/core';
import {RouterModule, RouterOutlet} from '@angular/router';
import { DefaultLayoutComponent } from '../@commons/layouts/default-layout/default-layout.component';
import { AppHeaderTabsComponent } from '../@commons/components/app-header-tabs/app-header-tabs.component';
import { SocialHomeComponent } from './social-home/social-home.component';
import {TabMenuItem} from '../@commons/classes/tab-menu-item';

@Component({
    selector: 'vw-social',
    templateUrl: './social.component.html',
    styleUrls: ['./social.component.scss'],
    standalone: true,
    imports: [RouterOutlet, DefaultLayoutComponent, RouterModule, AppHeaderTabsComponent, SocialHomeComponent]
})
export class SocialComponent implements OnInit {
  tabs: TabMenuItem[] = [{
    label: 'MENU.SOCIAL.SUBMENU.ARTICLES',
        routerLink: 'articles'
      },{
    label: 'MENU.SOCIAL.SUBMENU.MESSAGES',
        routerLink: 'messages'
      }];
    
  constructor() { }

  ngOnInit(): void {
  }

}
