import { Component, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { SdkConfigService } from "vegiwise-sdk-typescript/dist/config/sdk-config.service";
import { environment } from "../environments/environment";
import { MockConfig } from "vegiwise-sdk-typescript/dist/config/mock-config";
import { AuthApi } from "vegiwise-sdk-typescript/dist/sdk/auth/auth.api";
import { PermissionsService } from "./@commons/guards/navigation.guard";
import { StorageService } from "./@commons/services/front/storage.service";
import { JwtHelperService } from "./@commons/services/front/jwt-helper.service";

@Component({
  selector: "vw-admin",
  templateUrl: "./app.component.html",
  standalone: true,
  imports: [CommonModule, RouterOutlet, HttpClientModule],
  providers: [AuthApi, StorageService, JwtHelperService, PermissionsService],
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor() {}
  public open(modal: any): void {}

  ngOnInit(): void {
    const mockConfig: MockConfig = {
      dms: true,
      tasks: true,
      plants: false,
      cultivations: false,
      "cultivation-periods": false,
      "botanic-families": false,
      "growing-steps": false,
      risks: true,
      social: true,
      users: false,
      user: false,
      weather: true,
      locations: false,
      notifications: true,
      "plants-to-growing-steps" : false,
      "user-suggestions": false,
    };
    const sdkConfig: SdkConfigService = SdkConfigService.configure({
      baseUrl: environment.API_BASE,
      apiKey: "",
      mockConfig,
      accessTokenName: "access_token",
      refreshTokenName: "refresh_token",
      storeOnMobile: false,
    });
    sdkConfig.setMock(true, mockConfig);
  }
}
