import {Component, EventEmitter, Input, OnInit, Output, signal} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TextInputComponent} from "../../../../@commons/components/@generic/inputs/text-input/text-input.component";
import {FileUploadComponent} from "../../../../@commons/components/@forms/file-upload/file-upload.component";
import {TextareaComponent} from "../../../../@commons/components/@generic/inputs/text-area/text-area.component";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule
} from "@angular/forms";
import {LeafIconComponent} from "../../../../@commons/components/@generic/icons/leaf-icon/leaf-icon.component";
import {TranslateModule} from "@ngx-translate/core";
import {InputComponent} from "../../../../@commons/components/@generic/inputs/input/input.component";
import {ButtonComponent} from "../../../../@commons/components/@generic/buttons/button/button.component";
import {TrashIconComponent} from "../../../../@commons/components/@generic/icons/trash-icon/trash-icon.component";
import {
  RoundedButtonComponent
} from "../../../../@commons/components/@generic/buttons/rounded-button/rounded-button.component";
import {
  ArrowDownIconComponent
} from "../../../../@commons/components/@generic/icons/arrow-down-icon/arrow-down-icon.component";

@Component({
  selector: 'vw-edit-growing-step',
  standalone: true,
  imports: [CommonModule, TextareaComponent, TextInputComponent, FileUploadComponent, ReactiveFormsModule, LeafIconComponent, TranslateModule, InputComponent, ButtonComponent, TrashIconComponent, RoundedButtonComponent, ArrowDownIconComponent],
  templateUrl: './edit-growing-step.component.html',
  styleUrls: ['./edit-growing-step.component.scss']
})
export class EditGrowingStepComponent {
  @Input() formGroup: FormGroup;

  @Input() stepNumber: number;
  @Input() totalSteps: number;

  @Output() upClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() downClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  //@Input() formGroupName: string;

  constructor() {
  }

  deleteStep() {
    this.delete.emit();
  }

  up() {
    this.upClicked.emit();
  }

  down() {
    this.downClicked.emit();
  }
}
