<div class="flex">
  <vw-file-viewer (fileClicked)="onPictureClick()" class="w-full"
                  [selectedImage]="selectedImage"
                  [backgroundColor]="backgroundColor"
                  (deleteClicked)="deletePicture($event)"
                  [pictureUrl]="pictureUrl">
  </vw-file-viewer>
  <input
        #fileInput
        type="file"
        accept="image/jpeg, image/png, image/jpg"
        (change)="onFileSelected($event)"
        style="display: none" />
  <div class="m-4">
    <!-- Display metadata about the selectedImage -->
    <div *ngIf="selectedImage">
      <p>{{ selectedImage?.name }}</p>
      <p>{{ selectedImageSize }}</p>
      <p>{{ selectedImage?.type }}</p>
    </div>
  </div>
</div>
