import { Component, Optional, Self } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BaseInput } from "../../../../abstract/base-input";
import { NgControl } from "@angular/forms";
import { RoundedButtonComponent } from "../../buttons/rounded-button/rounded-button.component";

declare global {
  interface Window {
    EyeDropper?: any;
  }
}

@Component({
  selector: "vw-color-picker",
  standalone: true,
  imports: [CommonModule, RoundedButtonComponent],
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"],
})
export class ColorPickerComponent<T> extends BaseInput<T> {
  constructor(@Self() @Optional() control: NgControl) {
    super(control);
  }
  colors: any[] = [
    "#9747FFEE",
    "#30A156EE",
    "#F26B59EE",
    "#34BAF5EE",
    "#475569EE",
    "#000000",
    "#FFB757",
    "#34BAA5",
    "#8C92A4",
  ];
  selectedBorderColor: string = "#ffffff7d";
  eyeDropper: any;

  openEyeDroper($event: MouseEvent) {
    if (this.isEyeDropperSupported()) {
      if (!this.eyeDropper) {
        this.eyeDropper = new (window as any).EyeDropper();
      }

      if (this.eyeDropper) {
        this.eyeDropper
          .open()
          .then((res) => {
            this.select(res.sRGBHex);
          })
          .catch((e) => {
            console.error("color error");
            console.error(e);
          });
      }
    } else {
      alert("Not supported");
    }
  }

  select(color) {
    this.value = color;
    this.onChange(color);
    this.valueChange.emit(color);
  }

  delete() {
    this.value = null;
    this.valueChange.emit(null);
  }

  isEyeDropperSupported() {
    return "EyeDropper" in window;
  }
}
