<div class="w-6 h-6">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"></path>
        <path d="M8.75 12H16.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"></path>
        <path d="M12.5 8.25V15.75" stroke="white" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round"></path>
    </svg>
</div>