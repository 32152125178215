<input
    #fileInput
    type="file"
    accept="image/jpeg, image/png, image/jpg"
    (change)="onFilesSelected($event)"
    style="display: none" />
<label for="file-input" (click)="onClickUpload()">
    <div class="border border-dashed border-[#30A156] py-[12px] px-[20px]
    rounded-[8px] flex items-center justify-center gap-x-2 w-full cursor-pointer">
        <span>
            <vw-upload-file-icon></vw-upload-file-icon>
        </span>
        <span class="text-[15px] leading-6 font-medium text-[#30A156]">
            {{'COMMON.UPLOAD_PICTURE_VIDEO' | translate}}
        </span>
    </div>
</label>
<div class="flex items-center gap-x-2.5 mt-2">
    <vw-file-viewer *ngFor="let displayedImage of displayedImages" class="w-full"
                    [selectedImage]="displayedImage.selectedFile"
                    (deleteClicked)="deletePicture($event)"
                    [pictureUrl]="displayedImage.pictureUrl">
    </vw-file-viewer>
</div>