import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DefaultLayoutComponent } from "../../@commons/layouts/default-layout/default-layout.component";
import { AppDetailsHeaderComponent } from "../../@commons/components/app-details-header/app-details-header.component";
import { AppHeaderTabsComponent } from "../../@commons/components/app-header-tabs/app-header-tabs.component";
import { RouterOutlet } from "@angular/router";
import { AppEmulatorComponent } from "../../@commons/components/app-emulator/app-emulator.component";
import { PhonePreviewLayoutComponent } from "../../@commons/layouts/phone-preview-layout/phone-preview-layout.component";
import {TabMenuItem} from "../../@commons/classes/tab-menu-item";

@Component({
  selector: "vw-cultivation-details",
  standalone: true,
  imports: [
    CommonModule,
    DefaultLayoutComponent,
    AppDetailsHeaderComponent,
    AppHeaderTabsComponent,
    RouterOutlet,
    AppEmulatorComponent,
    PhonePreviewLayoutComponent,
  ],
  templateUrl: "./cultivation-details.component.html",
  styleUrls: ["./cultivation-details.component.scss"],
})
export class CultivationDetailsComponent {
  tabs: TabMenuItem[] = [{
    label: 'MENU.PLANT.SUBMENU.GENERAL',
    routerLink: 'global'
  },{
    label: 'MENU.PLANT.SUBMENU.PERIODS',
    routerLink: 'periods'
  },{
    label: 'MENU.PLANT.SUBMENU.STEPS',
    routerLink: 'steps'
  },{
    label: 'MENU.PLANT.SUBMENU.RISKS',
    routerLink: 'risks'
  }];

  /**
   * <!--
   *    <button routerLinkActive="menu-item-active"
   *            class="border-b-2 border-transparent px-[12px] py-[27px]" routerLink="periods">
   *       {{'MENU.PLANT.SUBMENU.PERIODS' | translate}}
   *    </button>
   *    <button routerLinkActive="menu-item-active"
   *            class="border-b-2 border-transparent px-[12px] py-[27px]" routerLink="steps">
   *       {{'MENU.PLANT.SUBMENU.STEPS' | translate}}
   *    </button>
   *    <button routerLinkActive="menu-item-active"
   *            class="border-b-2 border-transparent px-[12px] py-[27px]" routerLink="risks">
   *       {{'MENU.PLANT.SUBMENU.RISKS' | translate}}
   *    </button>-->
   */
}
