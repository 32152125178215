<div class="flex items-center justify-between pb-2">
  <div>
    <h2
      class="text-[32px] leading-[42px] font-bold"
      [class.max-w-[90%]]="displayCultivationSelector"
    >
      {{ label | translate }}
    </h2>

    <p class="text-[17px] font-medium left-7" *ngIf="description">
      {{ description | translate }}
    </p>
  </div>
  <vw-drop-down [options]="[{}, {}, {}]" *ngIf="displayCultivationSelector">
    <img
      src="/assets/cards/Product59.png"
      alt="image"
      class="w-[44px] h-full rounded-[8px]"
    />

    <div class="whitespace-nowrap">
      <p class="font-medium leading-6 text-base">Garlic</p>
      <p class="font-light leading-6 text-xs text-[#475569]">
        Vegetable & Plant
      </p>
    </div>
  </vw-drop-down>
</div>

<div class="h-[0.5px] w-full bg-[#ABB0C1] mt-5"></div>
