import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {Util} from "leaflet";
import template = Util.template;

@Component({
    template: '',
    standalone: true
})
export class AbstractButton implements OnInit, OnChanges {
    //background
    static DEFAULT_BACKGROUND_COLOR: string = '#30A156'
    static WARNING_BACKGROUND_COLOR: string = '#FA9B1B'
    static ALERT_BACKGROUND_COLOR: string = '#F26B59'
    static SECONDARY_BACKGROUND_COLOR: string = '#475569'
    static LIGHT_BACKGROUND_COLOR: string = '#F0F2F5'
    static TRANSPARENT_BACKGROUND_COLOR: string = '#00000026'
    //text
    static DEFAULT_TEXT_COLOR: string = '#FFFFFF'
    static ALERT_TEXT_COLOR: string = '#FFFFFF'
    static WARNING_TEXT_COLOR: string = '#FFFFFF'
    static SECONDARY_TEXT_COLOR: string = '#FFFFFF'
    static LIGHT_TEXT_COLOR: string = '#0500002C';
    //border
    static LIGHT_BORDER_COLOR: string = '#002C661A';
    @Input() label: string;
    @Input() backgroundColor:string;
    @Input() textColor:string;
    @Input() buttonClasses: string;
    @Input() borderColor: string;
    @Input() look: 'DEFAULT'|'SECONDARY'|'ALERT'|'WARNING'|'TRANSPARENT'|'LIGHT'|'CUSTOM';
    @Input() size: 'XS'|'S'|'M'|'L'|'XL' = 'M'

    ngOnInit() {
        this.computeStyle()
    }

    ngOnChanges(changes: SimpleChanges) {
        this.computeStyle();
    }

    computeStyle() {
        if (this.look) {
            switch (this.look) {
                case 'DEFAULT':
                    this.backgroundColor = AbstractButton.DEFAULT_BACKGROUND_COLOR;
                    this.textColor = AbstractButton.DEFAULT_TEXT_COLOR;
                    this.borderColor = this.backgroundColor;
                    break;
                case 'ALERT':
                    this.backgroundColor = AbstractButton.ALERT_BACKGROUND_COLOR;
                    this.textColor = AbstractButton.ALERT_TEXT_COLOR;
                    this.borderColor = this.backgroundColor;
                    break;
                case 'SECONDARY':
                    this.backgroundColor = AbstractButton.SECONDARY_BACKGROUND_COLOR;
                    this.textColor = AbstractButton.SECONDARY_TEXT_COLOR;
                    this.borderColor = this.backgroundColor;
                    break;
                case 'WARNING':
                    this.backgroundColor = AbstractButton.WARNING_BACKGROUND_COLOR;
                    this.textColor = AbstractButton.WARNING_TEXT_COLOR;
                    this.borderColor = this.backgroundColor;
                    break;
                case 'TRANSPARENT':
                    this.backgroundColor = AbstractButton.TRANSPARENT_BACKGROUND_COLOR;
                    this.textColor = AbstractButton.DEFAULT_TEXT_COLOR;
                    this.borderColor = this.backgroundColor;
                    break;
                case 'LIGHT':
                    this.backgroundColor = AbstractButton.LIGHT_BACKGROUND_COLOR;
                    this.borderColor = AbstractButton.LIGHT_BORDER_COLOR;
                    this.textColor = AbstractButton.LIGHT_TEXT_COLOR;
                    break;
                case 'CUSTOM':
                    //KEEP SET VALUES
                    break;
            }
        } else {
            this.look = 'DEFAULT';
            this.computeStyle();
        }
    }
}