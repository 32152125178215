import {Component, Input, Optional, Self, TemplateRef} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule
} from "@angular/forms";
import {BaseInput} from "../../../../abstract/base-input";
import {TranslateModule} from "@ngx-translate/core";
import {WritingIconComponent} from "../../icons/writing-icon/writing-icon.component";

@Component({
  selector: 'vw-text-input',
  standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, WritingIconComponent],
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent<T> extends BaseInput<T> {
  @Input() iconTemplate: TemplateRef<any>;


  focus = false;

  constructor(
      @Self() @Optional() control: NgControl
  ) {
    super(control);
  }

  currencyFormat(num: any): string {
    if (typeof num !== 'number') {
      num = parseFloat(num);
    }
    if (isNaN(num) || num === null || num === undefined) {
      return null;
    }
    return (
        num
            .toFixed(2) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') // use . as sperator
    );
  }

  onFocus() {
    this.focus = true;
  }

  onFocusOut() {
    this.focus = false;
  }
}
