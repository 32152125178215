<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
     xmlns="http://www.w3.org/2000/svg">
    <path d="M20.0002 12L4.00017 12" stroke="white" stroke-width="1.5"
          stroke-linecap="round" stroke-linejoin="round"/>
    <path
            d="M15.0004 17C15.0004 17 20.0003 13.3176 20.0003 12C20.0004 10.6824 15.0003 7 15.0003 7"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
    />
</svg>