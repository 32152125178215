<!-- logo -->
<div class="pb-[45px] border-b-[0.5px] border-[#B4B9CC]">
  <img src="/assets/common/vegiwise-logo.png" alt="logo" class="mx-auto pr-4" />
</div>

<!-- profile -->
<div class="py-[45px] flex flex-col items-center gap-y-[15px] text-center">
  <img
    src="/assets/images/avatars/5m.png"
    alt="avatar"
    class="w-[80px] h-[80px] rounded-full"
  />

  <div class="space-y-[10px]">
    <h3 class="text-[19px] leading-6 font-medium">{{ fullName }}</h3>

    <p class="text-[10px] leading-4 tracking-[1.5px] uppercase font-medium">
      <button (click)="onLogout()" class="inline">
        <span class="max-w-[16px] max-h-[16px]">
          <svg
            fill="#000000"
            height="16px"
            width="16px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 384.971 384.971"
            xml:space="preserve"
          >
            <g>
              <g id="Sign_Out">
                <path
                  d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03
			C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03
			C192.485,366.299,187.095,360.91,180.455,360.91z"
                />
                <path
                  d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279
			c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179
			c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </g>
          </svg>
        </span>
      </button>
    </p>
  </div>
</div>

<!-- sidebar buttons -->
<div
  class="space-y-[20px] text-[19px] left-6 overflow-y-auto max-h-[calc(100vh-400px)]"
>
  <button
    [routerLink]="'/cultivations'"
    routerLinkActive="app-menu-item-active"
    class="py-[30px] px-[40px] flex items-center gap-x-[15px] w-full"
  >
    <span class="max-w-[24px] max-h-[24px]">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 14H6.39482C6.68897 14 6.97908 14.0663 7.24217 14.1936L9.28415 15.1816C9.54724 15.3089 9.83735 15.3751 10.1315 15.3751H11.1741C12.1825 15.3751 13 16.1662 13 17.142C13 17.1814 12.973 17.2161 12.9338 17.2269L10.3929 17.9295C9.93707 18.0555 9.449 18.0116 9.025 17.8064L6.84211 16.7503"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M13 16.5L17.5928 15.0889C18.407 14.8352 19.2871 15.136 19.7971 15.8423C20.1659 16.3529 20.0157 17.0842 19.4785 17.3942L11.9629 21.7305C11.4849 22.0063 10.9209 22.0736 10.3952 21.9176L4 20.0199"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M12.5 13C14.75 13 17 11.9126 17 8.875C17 5.83743 12.5 2 12.5 2C12.5 2 8 5.83743 8 8.875C8 11.9126 10.25 13 12.5 13Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linejoin="round"
        ></path>
      </svg>
    </span>

    <span>{{ "MENU.PLANT.LABEL" | translate }}</span>
  </button>

  <button
    [routerLink]="'/home'"
    routerLinkActive="app-menu-item-active"
    class="py-[30px] px-[40px] flex items-center gap-x-[15px] w-full"
  >
    <span class="max-w-[24px] max-h-[24px]">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="12"
          cy="12"
          r="10"
          stroke="#30A156"
          stroke-width="1.5"
        ></circle>
        <path
          d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z"
          stroke="#30A156"
          stroke-width="1.5"
          stroke-linecap="round"
        ></path>
      </svg>
    </span>
    <span>{{ "MENU.USERS.LABEL" | translate }}</span>
  </button>

  <button
    [routerLink]="'/social'"
    routerLinkActive="app-menu-item-active"
    class="py-[30px] px-[40px] flex items-center gap-x-[15px] w-full"
  >
    <span class="max-w-[24px] max-h-[24px]">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.4785 18.75H11.5215C11.3461 18.75 11.1762 18.8115 11.0414 18.9238L6.75 22.5V18.75H4.5C4.30109 18.75 4.11032 18.671 3.96967 18.5303C3.82902 18.3897 3.75 18.1989 3.75 18V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5V14.6487C20.25 14.7587 20.2258 14.8673 20.1792 14.9668C20.1326 15.0664 20.0646 15.1545 19.9801 15.2249L15.9586 18.5762C15.8238 18.6885 15.6539 18.75 15.4785 18.75Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M15.75 8.25V12.75"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M11.25 8.25V12.75"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </span>
    <span>{{ "MENU.SOCIAL.LABEL" | translate }}</span>
  </button>
</div>
