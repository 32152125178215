<ng-template #defaultBody> DEFAULT BODY </ng-template>

<main
  class="grid grid-cols-[minmax(220px,0.13125fr)_minmax(400px,_1fr)_minmax(450px,0.334375fr)] max-w-[2760px] vh-screen mx-auto bg-[#EAECF2]"
>
  <vw-app-menu class="py-[45px] bg-white"></vw-app-menu>

  <div class="relative">
    <ng-container
      [ngTemplateOutlet]="bodyTemplate ? bodyTemplate : defaultBody"
    >
    </ng-container>
  </div>

  <!-- Align emulator vertically -->
  <div class="flex justify-center items-center bg-white">
    <div class="flex flex-col items-center justify-center">
      <vw-app-emulator></vw-app-emulator>
    </div>
  </div>
</main>
