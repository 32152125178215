import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RoundedButtonComponent} from "../../@generic/buttons/rounded-button/rounded-button.component";
import {TrashIconComponent} from "../../@generic/icons/trash-icon/trash-icon.component";
import {DmsApi} from "vegiwise-sdk-typescript/dist/sdk/dms/dms.api";
import {PreSignedUrlRes} from "vegiwise-sdk-typescript/dist/model/dms/pre-signed-url-res";
import {UploadFileIconComponent} from "../../@generic/icons/upload-file-icon/upload-file-icon.component";
import {DmsService} from "../../../services/vegiwise/dms.service";
import {UploadFileRes} from "vegiwise-sdk-typescript/dist/model/dms/upload-file-res";
import {ExternalFileDto} from "vegiwise-sdk-typescript/dist/model/@common/external-file";
import {ExternalFileOrigin} from "vegiwise-sdk-typescript/dist/enums/external-file-origin.enum";
import {FileViewerComponent} from "../../@generic/file-viewer/file-viewer.component";

@Component({
  selector: "vw-picture-uploader",
  standalone: true,
  imports: [
    CommonModule,
    RoundedButtonComponent,
    TrashIconComponent,
    UploadFileIconComponent,
    FileViewerComponent,
  ],
  providers: [DmsApi, DmsService],
  templateUrl: "./picture-uploader.component.html",
  styleUrls: ["./picture-uploader.component.scss"],
})
export class PictureUploaderComponent implements OnInit, OnChanges{
  @Input() backgroundColor: string;
  @Input() file: ExternalFileDto;
  @Output() fileChange: EventEmitter<ExternalFileDto> = new EventEmitter<ExternalFileDto>();
  @Input() pictureUrl: string;
  @ViewChild("fileInput") fileInput: ElementRef;

  selectedImage: File;
  constructor(private dmsApi: DmsApi, private dmsService: DmsService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes['file']) {
      this.buildPictureUrl();
    }
  }

  ngOnInit() {
    if(!this.file) {
      this.file = new ExternalFileDto();
    }
    this.buildPictureUrl();
  }

  deletePicture($event: MouseEvent) {
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.pictureUrl = null;
    this.file = null;
    this.fileChange.emit(this.file);
    this.selectedImage = null;
  }

  onPictureClick() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any) {
    this.selectedImage = event.target.files[0];
    this.previewImage(this.selectedImage);

    this.dmsApi.getPreSignedUrl().then((urlData: PreSignedUrlRes) => {
      let file = event.target.files[0];
      if (file) {
        this.dmsApi.uploadToPreSignedUrl(file, urlData).then((data: UploadFileRes) => {
          this.file = {
            fileKey: data.fileUid,
            fileOrigin: ExternalFileOrigin.ADMIN,
            name: this.selectedImage.name,
            bucketId: '',
            scope: '',
            contentType: this.selectedImage.type
          }
          this.fileChange.emit(this.file);
          this.buildPictureUrl();
        }).catch((e)=> {
          console.error(e);
        });
      }
    });
  }

  buildPictureUrl() {
    if(this.file && this.file.fileKey) {
      this.pictureUrl = `url(${this.dmsService.getFileUrl(this.file.fileKey, null, 300)})`;
    } else {
      this.pictureUrl = null;
    }
  }

  previewImage(file: File): void {
    const reader = new FileReader();

    reader.onload = (e) => {
      this.pictureUrl = `url(${e.target.result.toString()})`;
    };

    reader.readAsDataURL(file);
  }

  get selectedImageSize(): string {
    // If less than 1 MO => display in KO
    if (this.selectedImage && this.selectedImage.size < 1000000) {
      return `${Math.round(this.selectedImage.size / 1000)} ko`;
    }
    // If more than 1 MO => display in MO
    if (this.selectedImage && this.selectedImage.size >= 1000000) {
      return `${Math.fround(this.selectedImage.size / 1000000).toFixed(1)} Mo`;
    }
    return "";
  }
}
