<vw-form-group
    [format]="format"
    [value]="value"
    [name]="name"
    [onlyValue]="onlyValue"
    [label]="label"
    [prefix]="prefix"
    [toolTip]="toolTip"
    [error]="error"
    [inline]="inline"
    [errors]="errors"
    [errorField]="errorField"
    [showDefaultErrorLabel]="showDefaultErrorLabel"
    [errorFieldLabel]="errorFieldLabel"
    [errorLabel]="errorLabel"
    [success]="success"
    [labelClasses]="labelClasses"
    [successLabel]="successLabel"
>
    <select
        input-template
        (change)="sendSelectChange(selectField.value)"
        *ngIf="editMode"
        [class]="'w-full bg-white py-[12px] px-[20px] grow border-[0.5px] border-[#E1E1E1] rounded-[8px] ' + inputClasses"
        #selectField
        [class.open]="isOpen"
        [class.closed]="!isOpen"
        (click)="onSelectClick()"
        (blur)="onBlur()"
        [disabled]="!editMode || disabled"
        [class.form-control-plaintext]="!editMode"
        [required]="required"
        [id]="id"
        [name]="name"
        #select
    >
        <option *ngIf="placeholder" [disabled]="required" [selected]="!value" value="">{{ placeholder | translate }}</option>
        <option
            *ngFor="let item of values"
            [value]="(item !== null && item !== undefined && valueProperty ? item[valueProperty] : item)"
            [selected]="((value !== null && value !== undefined && valueProperty ? value[valueProperty] : value)) === ((item !== null && item !== undefined && valueProperty ? item[valueProperty] : item))">
            {{ ((prefix ? prefix + '.' : '') + (displayProperty ? item[displayProperty] : item)) | translate }}
        </option>
    </select>
    <label input-template *ngIf="!editMode" class="value input-template">
        {{ (value && prefix ? prefix + '.' : '') + (value ? (displayProperty ? value[displayProperty] : value) : placeholder) | translate }}
    </label>
</vw-form-group>
