import { Component, Optional, Self, Input, OnInit, ViewChild } from '@angular/core';
import {FormsModule, NgControl} from '@angular/forms';
import {BaseInput} from "../../../../abstract/base-input";
import {InputComponent} from "../input/input.component";
import {NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {FormGroupComponent} from "../form-group/form-group.component";
import {ButtonComponent} from "../../buttons/button/button.component";
import {Util} from "leaflet";
import isArray = Util.isArray;

@Component({
  selector: 'vw-button-select',
  templateUrl: './button-select.component.html',
  standalone: true,
  imports: [
    InputComponent,
    NgTemplateOutlet,
    TranslateModule,
    FormsModule,
    FormGroupComponent,
    NgIf,
    NgForOf,
    ButtonComponent
  ],
  styleUrls: ['./button-select.component.scss']
})
export class ButtonSelectComponent<T> extends BaseInput<T> implements OnInit {
  @Input() values: any[];
  @Input() valueProperty: string;
  @Input() displayProperty: string;
  @Input() selectedBackgroundColor: string = '#475569'
  @Input() defaultBackgroundColor: string = '#89899a';
  @Input() selectedTextColor: string = '#FFFFFF'
  @Input() defaultTextColor: string = '#CCCCCC'
  @Input() isMultiSelect: boolean = false;
  isOpen = false;

  get currentValue(): Array<any> {
    return (this.value !== null && this.value !== undefined && this.valueProperty) ? this.value[this.valueProperty] : this.value
  }


  constructor(
    @Self() @Optional() control: NgControl
  ) {
    super(control);
  }

  ngOnInit() {
  }

  onSelect(item: any) {
    if(!this.value) {
      this.value = [item];
    } else {

      if(!isArray(this.value)) {
        //convert to array (the value might have not been initialized as array)
        this.value = [this.value];
      }
      if(this.currentValue.includes(item)) {
        this.currentValue.splice(this.currentValue.indexOf(item), 1)
        this.value = this.currentValue;
      } else {
        if(this.isMultiSelect) {
          this.value.push(item)
        } else {
          this.value = [item]
        }
      }
    }
    this.valueChange.emit(this.value);
    this.onChange(this.value);
  }
}
