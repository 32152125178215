<section class="h-[calc(100vh-120px)] overflow-y-auto py-5 px-3">
    <div class="grid grid-cols-2 gap-3">
       <div class="w-full px-3 py-6 bg-[#FFFFFF] rounded-md">
            <h2>Available Time For Gardening</h2>
            <img src="https://source.unsplash.com/random/1920x1080" alt="">
        </div>
        <div class="w-full px-3 py-6 bg-[#FFFFFF] rounded-md">
            <h2>User's Information</h2>
            <img src="https://source.unsplash.com/random/1920x1080" alt="">
        </div>
        <div class="w-full px-3 py-6 bg-[#FFFFFF] rounded-md">
            <h2>User's on Heat Map</h2>
            <img src="https://source.unsplash.com/random/1920x1080" alt="">
        </div>
        <div class="w-full px-3 py-6 bg-[#FFFFFF] rounded-md">
            <h2>Age Pyramid</h2>
            <img src="https://source.unsplash.com/random/1920x1080" alt="">
        </div>
    </div>
</section>
