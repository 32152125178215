<main
  class="min-h-screen h-screen mx-auto flex flex-col justify-between overflow-auto"
>
  <section class="w-[500px] max-w-[500] mx-auto">
    <div class="h-[64px] w-full"></div>
    <img
      src="/assets/common/vegiwise-logo.png"
      alt="logo"
      class="h-[100px] min-w-[116px] mx-auto"
    />
    <div class="h-[50px] w-full"></div>
    <div class="w-full max-w-full">
      <form [formGroup]="form">
        <div class="mb-6">
          <label
            class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"
          >
            {{ "REGISTER.FORM.COMPANY_DATA" | translate }}
          </label>
          <!--<label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"> {{ 'REGISTER.FORM.COMPANY_NAME.LABEL' | translate }} </label>-->
          <vw-text-input
            [placeholder]="'REGISTER.FORM.COMPANYNAME.PLACEHOLDER'"
            [type]="'string'"
            [iconTemplate]="companyIcon"
            formControlName="companyNameCtrl"
          >
            <ng-template #companyIcon>
              <vw-office-icon></vw-office-icon>
            </ng-template>
          </vw-text-input>

          <!--<label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"> {{ 'REGISTER.FORM.VAT_NUMBER' | translate }} </label>-->
          <!--<label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"> {{ 'REGISTER.FORM.COMPANY_NAME.LABEL' | translate }} </label>-->
          <vw-text-input
            [placeholder]="'REGISTER.FORM.VATNUMBER.PLACEHOLDER'"
            [type]="'string'"
            [iconTemplate]="companyIcon2"
            formControlName="vatNumberCtrl"
          >
            <ng-template #companyIcon2>
              <vw-office-icon></vw-office-icon>
            </ng-template>
          </vw-text-input>
        </div>
        <div class="mb-6">
          <label
            class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"
          >
            {{ "REGISTER.FORM.PERSONAL_DATA" | translate }}
          </label>
          <!--<label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"> {{ 'REGISTER.FORM.NAME.LABEL' | translate }} </label>-->
          <vw-text-input
            [placeholder]="'REGISTER.FORM.NAME.PLACEHOLDER'"
            [type]="'string'"
            [iconTemplate]="nameIcon"
            formControlName="nameCtrl"
          >
            <ng-template #nameIcon>
              <vw-person-standing-icon></vw-person-standing-icon>
            </ng-template>
          </vw-text-input>
          <!--<label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"> {{ 'REGISTER.FORM.FIRSTNAME.LABEL' | translate }} </label>-->
          <vw-text-input
            [placeholder]="'REGISTER.FORM.FIRSTNAME.PLACEHOLDER'"
            [type]="'string'"
            [iconTemplate]="firstNameIcon"
            formControlName="firstNameCtrl"
          >
            <ng-template #firstNameIcon>
              <vw-person-standing-icon></vw-person-standing-icon>
            </ng-template>
          </vw-text-input>
          <!--<label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"> {{ 'REGISTER.FORM.EMAIL.LABEL' | translate }} </label>-->
          <vw-text-input
            [placeholder]="'REGISTER.FORM.EMAIL.PLACEHOLDER'"
            [type]="'email'"
            [iconTemplate]="mailIcon"
            formControlName="emailCtrl"
          >
            <ng-template #mailIcon>
              <vw-mail-icon></vw-mail-icon>
            </ng-template>
          </vw-text-input>
          <!--<label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"> {{ 'REGISTER.FORM.PASSWORD.LABEL' | translate }} </label>-->
          <vw-text-input
            [placeholder]="'REGISTER.FORM.PASSWORD.PLACEHOLDER'"
            [type]="'password'"
            [iconTemplate]="keyIcon"
            formControlName="passwordCtrl"
          >
            <ng-template #keyIcon>
              <vw-key-icon></vw-key-icon>
            </ng-template>
          </vw-text-input>
        </div>
        <!-- Error Message -->
        <vw-error-message
          *ngFor="let error of errors"
          [message]="error.message"
          [fieldName]="error.field"
          [formI18nPath]="'REGISTER.FORM'"
        ></vw-error-message>
        <div class="h-[50px] w-full"></div>
        <div class="flex items-center gap-x-[12px]">
          <button
            class="bg-white hover:bg-white/80 border-[0.5px] border-[#D0D0D1] rounded-[7px] text-base font-medium text-[#202939] px-6 py-2.5 w-full"
            [routerLink]="'/auth/login'"
            type="button"
          >
            {{ "REGISTER.FORM.LOGIN" | translate }}
          </button>
          <button
            class="bg-[#30A156] hover:bg-[#30A156]/80 border-[0.5px] border-[#30A156] rounded-[7px] text-base font-medium text-white px-6 py-2.5 w-full"
            (click)="createAccount()"
            type="button"
          >
            {{ "REGISTER.FORM.REGISTER" | translate }}
          </button>
        </div>
      </form>
    </div>
    <div class="h-[64px] w-full"></div>
  </section>
</main>
