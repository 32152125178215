import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {AbstractButton} from "../abstract-button";

@Component({
  selector: 'vw-rounded-button',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './rounded-button.component.html',
  styleUrls: ['./rounded-button.component.scss']
})
export class RoundedButtonComponent extends AbstractButton implements OnInit, OnChanges{
  @Input() height: number;
  @Input() width: number;

  computeStyle() {
    if(this.size) {
      switch (this.size) {
        case "XS":
          this.width = this.height = 25;
          break;
        case "S":
          this.width = this.height = 32;
          break;
        case "M":
          this.width = this.height = 38;
          break;
        case "L":
          this.width = this.height = 45;
          break;
        case "XL":
          this.width = this.height = 52;
          break;
      }
    }
    super.computeStyle();
  }

}
