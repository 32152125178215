import {Component, Input, OnInit, Optional, Self, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ArrowDownIconComponent} from "../../icons/arrow-down-icon/arrow-down-icon.component";
import {BaseInput} from "../../../../abstract/base-input";
import {NgControl} from "@angular/forms";
import {TextInputComponent} from "../text-input/text-input.component";

@Component({
  selector: 'vw-drop-down',
  standalone: true,
  imports: [CommonModule, ArrowDownIconComponent, TextInputComponent],
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent<T> extends BaseInput<T> implements OnInit {
  @Input() options: any[];
  isOpen = false;
  public dropdown: string = '';



    constructor( @Self() @Optional() control: NgControl) {
    super(control);
  }

  ngOnInit() {
  }


  selectOption($event: any, optionIndex: number) {
    this.options.forEach((opt: any, index: number) => {
      opt.isActive = optionIndex === index;
    });
    this.dropdown = $event.target.innerHTML;
  }
}
