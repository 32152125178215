import {Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {NgClass, NgIf, NgSwitch, UpperCasePipe} from "@angular/common";

@Component({
  selector: 'vw-form-group',
  templateUrl: './form-group.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    NgSwitch,
    UpperCasePipe,
    NgIf,
    NgClass
  ],
  styleUrls: ['./form-group.component.scss']
})
export class FormGroupComponent implements OnChanges {
  @Input() format: RegExp;
  @Input() value: any;
  @Input() name: string = 'input' + ((Math.random() * 1000) + 0);
  // labels, tooltip & i18n
  @Input() onlyValue = false;
  @Input() label = '';
  @Input() prefix = '';
  @Input() toolTip: string;
  @Input() labelClasses = '';
  @Input() inline = false;
  @Input() formGroupClasses = '';
  // errors...
  @Input() error = false;
  @Input() errors: any = {};
  @Input() errorField = '';
  @Input() errorFieldLabel = '';
  @Input() errorLabel = '';
  @Input() showDefaultErrorLabel = true;
  // success...
  @Input() success = false;
  @Input() successLabel: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.value) {
      this.errors = null;
    }
  }
}
