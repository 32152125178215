<!-- right sidebar -->
<!--<button
  class="px-[18px] py-[8px] flex items-center gap-x-1.5 bg-[#F5F6F8] rounded-[5px] mx-auto"
>
  <span
    ><svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8906 12.846C18.5371 14.189 16.8667 15.138 13.5257 17.0361C10.296 18.8709 8.6812 19.7884 7.37983 19.4196C6.8418 19.2671 6.35159 18.9776 5.95624 18.5787C5 17.6139 5 15.7426 5 12C5 8.2574 5 6.3861 5.95624 5.42132C6.35159 5.02245 6.8418 4.73288 7.37983 4.58042C8.6812 4.21165 10.296 5.12907 13.5257 6.96393C16.8667 8.86197 18.5371 9.811 18.8906 11.154C19.0365 11.7084 19.0365 12.2916 18.8906 12.846Z"
        stroke="black"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  </span>

  <span>Preview</span>
</button>-->

<div class="mx-auto">
  <!-- https://github.com/picturepan2/devices.css -->
  <div class="device device-iphone-14-pro mx-auto">
    <div class="device-frame">
      <iframe
        src="https://dev.vegiwise.com"
        frameborder="0"
        class="w-full h-full rounded-[60px]"
      ></iframe>
    </div>
    <div class="device-stripe"></div>
    <div class="device-header"></div>
    <div class="device-sensors"></div>
    <div class="device-btns"></div>
    <div class="device-power"></div>
    <div class="device-home"></div>
  </div>
</div>
