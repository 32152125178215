import { Component, OnInit } from '@angular/core';
import {RouterModule, RouterOutlet} from '@angular/router';
@Component({
    selector: 'vw-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    standalone: true,
    imports: [RouterOutlet, RouterModule]
})
export class AuthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
