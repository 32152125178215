<div class="p-5 space-y-[15px] mb-5 rounded-[8px] bg-white border-[0.5px] border-[#B4B9CC] text-[15px] leading-4" [formGroup]="formGroup">
    <div class="flex items-center justify-between">
        <div class="flex items-center gap-x-[15px]">
            <div class="w-[32px] h-[32px] flex items-center justify-center bg-[#FA9B1B] rounded-full">
                <vw-leaf-icon></vw-leaf-icon>
            </div>
            <p class="text-lg leading-6 font-medium">{{'CULTIVATION.STEPS.ITEM.TITLE' | translate:  {
                stepNumber: stepNumber +1,
                totalSteps: totalSteps
            } }}</p>
            <vw-text-input [placeholder]="'CULTIVATION.STEPS.ITEM.NAME.PLACEHOLDER'"
                           [formControlName]="'nameCtrl'"></vw-text-input>
        </div>
        <div class="flex items-end gap-x-[10px]">
            <vw-rounded-button [look]="'TRANSPARENT'" (click)="down()" *ngIf="stepNumber < totalSteps -1"
                    [buttonClasses]="'border border-1'"
                    [size]="'S'" >
                <vw-arrow-down-icon class="btn-icon"></vw-arrow-down-icon>
            </vw-rounded-button>
            <vw-rounded-button  [look]="'TRANSPARENT'" (click)="up()" *ngIf="stepNumber > 0"
                    [buttonClasses]="'border border-1'"
                    [size]="'S'" >
                <vw-arrow-down-icon class="btn-icon rotate-180"></vw-arrow-down-icon>
            </vw-rounded-button>
            <vw-button [look]="'ALERT'">
                <vw-trash-icon class="btn-icon" (click)="deleteStep()"></vw-trash-icon>
            </vw-button>
        </div>
    </div>
    <div class="h-[0.5px] w-full bg-[#B4B9CC]"></div>
    <div class="space-y-[12px]">
        <div class="flex items-center gap-x-[12px]">
            <span>{{'CULTIVATION.STEPS.ITEM.DURATION_DAYS.LABEL' | translate}}</span>
            <vw-text-input [placeholder]="'CULTIVATION.STEPS.ITEM.DURATION_DAYS.PLACEHOLDER'" type="number" [min]="1" [step]="1"
                           [formControlName]="'durationDaysCtrl'"></vw-text-input>
            <span>{{'CULTIVATION.STEPS.ITEM.DURATION_DAYS.UNIT' | translate}}</span>
        </div>
        <vw-text-area [placeholder]="'CULTIVATION.STEPS.ITEM.DESCRIPTION.PLACEHOLDER'" [rows]="7"
                      [formControlName]="'descriptionCtrl'"></vw-text-area>
        <vw-file-upload [formControlName]="'filesCtrl'">
        </vw-file-upload>
    </div>
</div>