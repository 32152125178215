<vw-default-layout [bodyTemplate]="bodyT">
  <ng-template #bodyT>
    <!-- navbar -->
    <div>
      <nav
        class="bg-white pt-[18px] pr-[65px] pb-[18px] pl-[35px] flex flex-row items-center justify-between w-full relative hover:opacity-80 transition-opacity duration-300 ease-in-out"
      >
        <div class="relative">
          <input
            type="search"
            class="w-[540px] p-[12px] pl-[45px]"
            placeholder="Filter or search by plants name"
          />
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute top-1/2 -translate-y-1/2 left-1.5"
          >
            <path
              d="M25.1363 19.197C25.4292 19.4899 25.9041 19.4899 26.197 19.197C26.4899 18.9041 26.4899 18.4292 26.197 18.1363L25.1363 19.197ZM22.103 16.1637L25.1363 19.197L26.197 18.1363L23.1637 15.103L22.103 16.1637ZM24.9 10.325C24.9 6.14144 21.5086 2.75 17.325 2.75V4.25C20.6801 4.25 23.4 6.96987 23.4 10.325H24.9ZM17.325 2.75C13.1414 2.75 9.75 6.14144 9.75 10.325H11.25C11.25 6.96987 13.9699 4.25 17.325 4.25V2.75ZM9.75 10.325C9.75 14.5086 13.1414 17.9 17.325 17.9V16.4C13.9699 16.4 11.25 13.6801 11.25 10.325H9.75ZM17.325 17.9C21.5086 17.9 24.9 14.5086 24.9 10.325H23.4C23.4 13.6801 20.6801 16.4 17.325 16.4V17.9Z"
              fill="#475569"
            ></path>
            <path
              d="M2.33325 7L6.99992 7.00003"
              stroke="#475569"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M2.33325 15.1667H6.99992"
              stroke="#475569"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M2.33325 24.5H24.4999"
              stroke="#475569"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </div>

        <vw-button [label]="'Create new plant'" (click)="createNew($event)">
          <vw-plus-icon class="btn-icon"></vw-plus-icon>
        </vw-button>
      </nav>
      <!-- content -->
      <div class="flex flex-wrap gap-[15px] p-[35px]">
        <vw-cultivation-item
          class="h-[220px] w-full bg-no-repeat bg-cover bg-center relative rounded-[10px] overflow-hidden text-white max-w-[160px]"
          [cultivation]="cultivation"
          *ngFor="let cultivation of cultivations"
          (click)="goToCultivationDetails(cultivation)"
        ></vw-cultivation-item>
      </div>
    </div>
  </ng-template>
</vw-default-layout>
