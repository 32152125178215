import {
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges, OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {UploadFileIconComponent} from "../../@generic/icons/upload-file-icon/upload-file-icon.component";
import {TranslateModule} from "@ngx-translate/core";
import {ExternalFileDto} from "vegiwise-sdk-typescript/dist/model/@common/external-file";
import {DmsApi} from "vegiwise-sdk-typescript/dist/sdk/dms/dms.api";
import {DmsService} from "../../../services/vegiwise/dms.service";
import {PreSignedUrlRes} from "vegiwise-sdk-typescript/dist/model/dms/pre-signed-url-res";
import {UploadFileRes} from "vegiwise-sdk-typescript/dist/model/dms/upload-file-res";
import {ExternalFileOrigin} from "vegiwise-sdk-typescript/dist/enums/external-file-origin.enum";
import { v4 as uuidv4 } from "uuid";
import {FileViewerComponent} from "../../@generic/file-viewer/file-viewer.component";
import {ControlValueAccessor, NgControl} from "@angular/forms";

export class SelectedImage {
  uid: string;
  selectedFile: File;
  externalFile: ExternalFileDto;
  pictureUrl: string;
}
@Component({
  selector: 'vw-file-upload',
  standalone: true,
  imports: [CommonModule, UploadFileIconComponent, TranslateModule, FileViewerComponent],
  providers: [DmsApi, DmsService],
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements ControlValueAccessor, OnChanges, OnInit {
  @ViewChild("fileInput") fileInput: ElementRef;

  @Input() value: any = [];
  @Input() id: string = 'input-files' + ((Math.random() * 1000) + 0);
  @Input() name: string = 'input-files' + ((Math.random() * 1000) + 0);

  @Output() enterPressed: EventEmitter<void> = new EventEmitter<void>();
  @Output() valueChange: EventEmitter<ExternalFileDto[]> = new EventEmitter<ExternalFileDto[]>();
  disabled: boolean = false;
  displayedImages: SelectedImage[];
  constructor(
      @Self() @Optional() private control: NgControl,
      private dmsApi: DmsApi, private dmsService: DmsService) {
    if (control) {
      control.valueAccessor = this;
    }
  }

  onChange = (v: ExternalFileDto[]) => { };
  onTouched = () => { };

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes['file']) {
      this.mapFilesToDisplayedImages();
      this.buildPictureUrls();
    }
  }

  ngOnInit() {
    if(!this.value) {
      this.value = [];
      this.displayedImages = [];
    }
    this.mapFilesToDisplayedImages();
    this.buildPictureUrls();
  }

  deletePicture(file: ExternalFileDto) {
    this.value.splice(this.value.indexOf(file), 1);
    this.sendChange();
  }

  onClickUpload() {
    this.fileInput.nativeElement.click();
  }

  /**
   * upload multiple files
   * @param event
   */
  onFilesSelected(event: any) {

    //map file to selectedImage
    this.displayedImages = Array.from(event.target.files).map((file: File): SelectedImage => {
      return {
        uid: uuidv4(),
        selectedFile: file,
        externalFile: null,
        pictureUrl: null,
      };
    })

    const preSignedUrlsPromisses: Promise<PreSignedUrlRes>[] = this.displayedImages.map((selectedImage: SelectedImage) => {
      return this.dmsApi.getPreSignedUrl() //TODO make a single call to retrive multiple urls ?
    });

    this.previewImages(); // will add a url to the selectedImageObject

    const backupImages = this.displayedImages;

    Promise.all(preSignedUrlsPromisses).then((urlDatas: PreSignedUrlRes[]) => {

      const uploadFilePromisses: Promise<UploadFileRes>[] = [];

      urlDatas.forEach((urlData) => {
        let file: SelectedImage[] = backupImages.splice(0, 1);
        if(file && file.length) {
          uploadFilePromisses.push(this.dmsApi.uploadToPreSignedUrl(file[0].selectedFile, urlData));
        }
      });

      Promise.all(uploadFilePromisses).then((uploadFileResponses: UploadFileRes[]) => {
        const uploadedFiles: ExternalFileDto[] = uploadFileResponses.map((data: UploadFileRes) => {
          return {
            fileKey: data.fileUid,
            fileOrigin: ExternalFileOrigin.ADMIN,
            name: data.fileUid,
            bucketId: '',
            scope: '',
            //contentType: this.selectedImage.type
          };
        });

        const v = this.value;
        v.push(...uploadedFiles);
        this.value = v;
        this.mapFilesToDisplayedImages();
        this.buildPictureUrls();
        this.sendChange();

      });
    });

  }

  mapFilesToDisplayedImages() {
    this.displayedImages = this.value.map((file: ExternalFileDto ): SelectedImage => {
      return {
        uid: '',
        externalFile: file,
        selectedFile: null,
        pictureUrl: null
      };
    })
  }

  buildPictureUrls() {
    this.displayedImages.forEach((displayedImage) => {
      if(displayedImage.externalFile && displayedImage.externalFile.fileKey) {
        displayedImage.pictureUrl = `url(${this.dmsService.getFileUrl(displayedImage.externalFile.fileKey, null, 300)})`;
      } else {
        displayedImage.pictureUrl = null;
      }
    });

  }

  previewImages(): void {
    this.displayedImages.forEach((selectedImage: any) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        selectedImage.pictureUrl = `url(${e.target.result.toString()})`;
      };
      reader.readAsDataURL(selectedImage.selectedFile);
    })
  }


  // CONTROL VALUE ACCESSOR METHODS
  public get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  public get showError(): boolean {
    if (!this.control) {
      return false;
    }
    const { dirty, touched } = this.control;
    return this.invalid ? (dirty || touched) : false;
  }

  // Allows Angu&lar to update the model (rating).
  // Update the model and changes needed for the view here.
  writeValue(v: any): void {
    this.value = v;
    this.onChange(this.value);
  }
  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }
  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  sendChange() {
    this.valueChange.emit(this.value);
    this.onChange(this.value);
  }

  onKeyPress(event): void {
    if (event.key === 'Enter') {
      this.enterPressed.emit();
    }
  }
}
