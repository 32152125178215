import { Routes, ExtraOptions } from "@angular/router";
import { NavigationGuard } from "./@commons/guards/navigation.guard";
import { HomePageComponent } from "./home/home-page/home-page.component";
import { HomeProductsComponent } from "./home/home-products/home-products.component";
import { HomeUsersComponent } from "./home/home-users/home-users.component";
import { HomeComponent } from "./home/home.component";
import { SocialComponent } from "./social/social.component";
import { CultivationsComponent } from "./cultivations/cultivations.component";
import { SocialHomeComponent } from "./social/social-home/social-home.component";
import { SocialMessagesComponent } from './social/social-messages/social-messages.component';
import { CultivationListComponent } from "./cultivations/cultivation-list/./cultivation-list.component";
import { CultivationDetailsComponent } from "./cultivations/cultivation-details/cultivation-details.component";
import { EditGeneralDataComponent } from "./cultivations/cultivation-details/edit-general-data/edit-general-data.component";
import { EditPeriodsComponent } from "./cultivations/cultivation-details/edit-periods/edit-periods.component";
import { EditRisksComponent } from "./cultivations/cultivation-details/edit-risks/edit-risks.component";
import { EditGrowingStepsComponent } from "./cultivations/cultivation-details/edit-growing-steps/edit-growing-steps.component";
import { LoginComponent } from "./auth/login/login.component";
import { AuthComponent } from "./auth/auth.component";
import { RegisterComponent } from "./auth/register/register.component";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { ConfirmComponent } from "./auth/confirm/confirm.component";
import { ConfirmPasswordComponent } from "./auth/confirm-password/confirm-password.component";

export const APP_ROUTES: Routes = [
  /* {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(mod => mod.AuthModule),
  },*/
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "register", component: RegisterComponent },
      { path: "confirm", component: ConfirmComponent },
      { path: "forgot-password", component: ForgotPasswordComponent },
      { path: "confirm-password", component: ConfirmPasswordComponent },
      { path: "reset-password", component: ResetPasswordComponent },
      { path: "", pathMatch: "full", redirectTo: "login" },
    ],
  },
  {
    path: "home",

    component: HomeComponent,
    children: [
      { path: "leads", component: HomePageComponent },
      { path: "products", component: HomeProductsComponent },
      { path: "users", component: HomeUsersComponent },
      { path: "", pathMatch: "full", redirectTo: "leads" },
    ],
    //loadChildren: () => import('./home/home.routes').then(home => home.routes),
    canActivate: [NavigationGuard],
  },
  {
    path: "social",
    component: SocialComponent,
    children: [
      { path: "articles", component: SocialHomeComponent },
      { path: "messages", component: SocialMessagesComponent },
      { path: "", pathMatch: "full", redirectTo: "articles" },
    ],
    //loadChildren: () => import('./social/social.routes').then(social => social.routes),
    canActivate: [NavigationGuard],
  },
  {
    path: "cultivations",
    component: CultivationsComponent,
    children: [
      { path: "", component: CultivationListComponent },
      {
        path: ":cultivationUid",
        component: CultivationDetailsComponent,
        children: [
          {
            path: "global",
            pathMatch: "full",
            component: EditGeneralDataComponent,
          },
          {
            path: "periods",
            pathMatch: "full",
            component: EditPeriodsComponent,
          },
          { path: "risks", pathMatch: "full", component: EditRisksComponent },
          {
            path: "steps",
            pathMatch: "full",
            component: EditGrowingStepsComponent,
          },
          { path: "", pathMatch: "full", redirectTo: "global" },
        ],
      },
    ],
    //loadChildren: () => import('./marketplace/marketplace.routes').then(marketplace => marketplace.routes),
    canActivate: [NavigationGuard],
  },
  {
    path: "cultivations/:uid",
    component: CultivationDetailsComponent,
  },
  //{ path: 'not-found', component: NotFoundComponent },
  { path: "**", redirectTo: "/home", pathMatch: "full" },
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: "always",
  enableTracing: false,
  errorHandler: (error) => (e) => {
    console.log(e);
    console.log("error routing ...");
  },
};
