<div class="m-5 px-3 py-6 bg-[#FFFFFF] rounded-md">
    <form class="relative" method="post">
        <fieldset>
            <legend>Create a new post</legend>
            <div class="flex flex-col mb-4">
                <label for="file-input" class="relative cursor-pointer">
                    <input type="file" id="file-input" class="hidden" accept="image/*">
                    <div class="flex items-center justify-center border border-dashed rounded-md border-[#30A156] p-3">
                        <svg class="w-6 h-6 mr-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3334 5.59984C18.6647 5.59984 18.9334 5.33121 18.9334 4.99984C18.9334 4.66847 18.6647 4.39984 18.3334 4.39984V5.59984ZM11.6667 4.39984C11.3353 4.39984 11.0667 4.66847 11.0667 4.99984C11.0667 5.33121 11.3353 5.59984 11.6667 5.59984V4.39984ZM15.6 1.6665C15.6 1.33513 15.3314 1.0665 15 1.0665C14.6686 1.0665 14.4 1.33513 14.4 1.6665H15.6ZM14.4 8.33317C14.4 8.66454 14.6686 8.93317 15 8.93317C15.3314 8.93317 15.6 8.66454 15.6 8.33317H14.4ZM18.3334 4.39984H15V5.59984H18.3334V4.39984ZM15 4.39984H11.6667V5.59984H15V4.39984ZM14.4 1.6665V4.99984H15.6V1.6665H14.4ZM14.4 4.99984V8.33317H15.6V4.99984H14.4Z" fill="#30A156"/>
                            <path d="M9.58335 2.5C5.8514 2.5 3.98542 2.5 2.82606 3.65937C1.66669 4.81874 1.66669 6.68471 1.66669 10.4167C1.66669 14.1486 1.66669 16.0146 2.82606 17.174C3.98542 18.3333 5.8514 18.3333 9.58335 18.3333C13.3153 18.3333 15.1813 18.3333 16.3407 17.174C17.5 16.0146 17.5 14.1486 17.5 10.4167V10" stroke="#30A156" stroke-width="1.2" stroke-linecap="round"/>
                            <path d="M4.16669 17.4997C7.67501 13.5402 11.6177 8.28926 17.5 12.2275" stroke="#30A156" stroke-width="1.2"/>
                            </svg>
                        <span class="text-[#30A156]">Choose pictures/videos</span>
                    </div>
                </label>
            </div>
            <div class="flex flex-col mb-4">
                <label for="description">Add a description:</label>
                <textarea name="" id="" rows="2" placeholder="Write your post" class="mt-2 px-3 py-2 bg-gray-100 border rounded-md w-full"></textarea>
            </div>
            <input
                type="submit"
                value="Post now"
                class="py-[9px] px-4 bg-[#30A156] text-white text-[15px] leading-6 font-medium rounded-[9px]"
            />
        </fieldset>
    </form>
</div> 

