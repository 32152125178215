<p>home-users works!</p>
<section class="h-[calc(100vh-120px)] overflow-y-auto py-5 px-3">
    <div class="grid grid-cols-2 gap-3">
       <div class="w-full px-3 py-6 bg-[#FFFFFF] rounded-md">
        </div>
        <div class="w-full px-3 py-6 bg-[#FFFFFF] rounded-md">
        </div>
        <div class="w-full px-3 py-6 bg-[#FFFFFF] rounded-md">
        </div>
        <div class="w-full px-3 py-6 bg-[#FFFFFF] rounded-md">
        </div>
    </div>
</section>
