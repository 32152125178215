import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppEmulatorComponent } from "../../../@commons/components/app-emulator/app-emulator.component";
import { EditCultivationHeaderComponent } from "../@commons/edit-cultivation-header/edit-cultivation-header.component";
import { EditCultivationFooterComponent } from "../@commons/edit-cultivation-footer/edit-cultivation-footer.component";
import { BulletLabelComponent } from "../../../@commons/components/@forms/bullet-label/bullet-label.component";
import { ColorPickerComponent } from "../../../@commons/components/@generic/inputs/color-picker/color-picker.component";
import { EditPeriodComponent } from "./edit-period/edit-period.component";
import { CultivationPeriodDto } from "vegiwise-sdk-typescript/dist/model/@common/cultivation-period";
import { AbstractCultivationEditor } from "../@abstract/abstract-cultivation-editor";
import { ActivatedRoute, Router } from "@angular/router";
import { CultivationApi } from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation.api";
import { CultivationDto } from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { EditGrowingStepComponent } from "../edit-growing-steps/edit-growing-step/edit-growing-step.component";
import { CultivationPeriodType } from "vegiwise-sdk-typescript/dist/enums/cultivation-period-type";
import { ButtonComponent } from "../../../@commons/components/@generic/buttons/button/button.component";
import { PlusIconComponent } from "../../../@commons/components/@generic/icons/plus-icon/plus-icon.component";
import { CultivationPeriodApi } from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation-period.api";
import * as moment from "moment";
import { Moment } from "moment";
import { StorageService } from "src/app/@commons/services/front/storage.service";
import {StringUtils} from "../../../@commons/utils/string-utils";
import {PagingResponse} from "../../../@commons/services/base.service";
import {
  UpsertCultivationPeriodRequest
} from "vegiwise-sdk-typescript/dist/model/plant/cultivation-period/upsert-cultivation-period-req";

@Component({
  selector: "vw-edit-periods",
  standalone: true,
  imports: [
    CommonModule,
    AppEmulatorComponent,
    EditCultivationHeaderComponent,
    EditCultivationFooterComponent,
    BulletLabelComponent,
    ColorPickerComponent,
    EditPeriodComponent,
    FormsModule,
    EditGrowingStepComponent,
    ReactiveFormsModule,
    ButtonComponent,
    PlusIconComponent,
  ],
  templateUrl: "./edit-periods.component.html",
  providers: [CultivationApi, CultivationPeriodApi],
  styleUrls: ["./edit-periods.component.scss"],
})
export class EditPeriodsComponent extends AbstractCultivationEditor {

  cultivationPeriods: CultivationPeriodDto[];

  form: FormGroup = this.fb.group({
    periodCtrls: this.fb.array([]),
  });

  get periodCtrls(): FormArray {
    return this.form.get("periodCtrls") as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cultivationApi: CultivationApi,
    protected cultivationPeriodApi: CultivationPeriodApi,
    protected readonly storageService: StorageService
  ) {
    super(router, activatedRoute, cultivationApi, "steps", storageService);
  }

  clearForm() {
    this.periodCtrls.clear();
  }

  addPeriod(event) {
    this.periodCtrls.push(this.newPeriodCtrl());
  }

  newPeriodCtrl(period: CultivationPeriodDto = null): FormGroup {
    return this.fb.group({
      typeCtrl: period ? period.type : "",
      startDateCtrl: period
        ? moment.months()[moment(period.startDate).month()]
        : "",
      endDateCtrl: period
        ? moment.months()[moment(period.endDate).month()]
        : "",
      uidCtrl: period ? period.uid : "",
    });
  }

  ngOnInit() {
    super.onInit();
  }

  loadCultivation() {
    super.loadCultivation();
    if (StringUtils.isUUID(this.cultivationUid)) {
      this.cultivationPeriodApi
          .list({cultivationUid: this.cultivationUid}, 0, 15, this.currentLanguage)
          .then((p: PagingResponse<CultivationPeriodDto>) => {
            this.cultivationPeriods = p.results;
            this.mapCultivationPeriodsToForm();
          });
    }
  }

  save($event) {
    if (this.form.valid) {
      this.mapFormToCultivationPeriods();
      this.cultivationPeriodApi.upsertMany(this.cultivationPeriods.map((p: CultivationPeriodDto) => {
        //map to upsertRequest
        const upsertPeriodDto: UpsertCultivationPeriodRequest = new UpsertCultivationPeriodRequest();
        upsertPeriodDto.type = p.type;
        upsertPeriodDto.startDate = p.startDate;
        upsertPeriodDto.endDate = p.endDate;
        upsertPeriodDto.uid = p.uid;
        upsertPeriodDto.cultivationUid = this.cultivationUid;

        return upsertPeriodDto;
      })).then((res) => {
        this.cultivationPeriods = res;
      });
      }
  }

  getMonthByName(name: string): Moment {
    const monthNumber = moment().month(name.toLowerCase()).month();
    return moment().set("month", monthNumber);
  }
  onDelete(i: number) {
    const selectedUid = this.periodCtrls.controls[i].get("uidCtrl").value;
    if (selectedUid) {
      //TODO check if we need to delete from period or simply remove from parent
      this.cultivationPeriodApi.deleteByUid(selectedUid).then((res) => {
        this.periodCtrls.controls.splice(i, 1);
      });
    }
  }

  mapCultivationPeriodsToForm() {
      if (this.cultivationPeriods) {
        this.cultivationPeriods.forEach((period: CultivationPeriodDto) => {
          this.periodCtrls.push(this.newPeriodCtrl(period));
        });
      }
  }

  mapFormToCultivationPeriods(): CultivationDto {
    this.cultivationPeriods = this.periodCtrls.controls.map((ctrl) => {
      const p: CultivationPeriodDto = new CultivationPeriodDto();
      const uid = ctrl.get("uidCtrl").value;
      //const existingCultivationPeriod = this.cultivationPeriods.find(c => c.uid === uid);
      p.type = CultivationPeriodType[ctrl.get("typeCtrl").value];
      p.startDate = this.getMonthByName(ctrl.get("startDateCtrl").value)
        .startOf("month")
        .toDate();
      p.endDate = this.getMonthByName(ctrl.get("endDateCtrl").value)
        .endOf("month")
        .toDate();
      if(uid) {
        p.uid = uid;
      }
      return p;
    });
    return this.cultivation;
  }

  mapFormToCultivation(): CultivationDto {
    return undefined;
  }

  mapCultivationToForm() {
  }
}
