import {Component} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AppEmulatorComponent} from "../../../@commons/components/app-emulator/app-emulator.component";
import {EditCultivationHeaderComponent} from "../@commons/edit-cultivation-header/edit-cultivation-header.component";
import {EditCultivationFooterComponent} from "../@commons/edit-cultivation-footer/edit-cultivation-footer.component";
import {EditGrowingStepComponent} from "./edit-growing-step/edit-growing-step.component";
import {GrowingStepDto} from "vegiwise-sdk-typescript/dist/model/@common/growing-step";
import {AbstractCultivationEditor} from "../@abstract/abstract-cultivation-editor";
import {ActivatedRoute, Router} from "@angular/router";
import {CultivationApi} from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation.api";
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule,} from "@angular/forms";
import {LabelUtils} from "../../../@commons/utils/label-utils";
import {CultivationDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import {ButtonComponent} from "../../../@commons/components/@generic/buttons/button/button.component";
import {PlusIconComponent} from "../../../@commons/components/@generic/icons/plus-icon/plus-icon.component";
import {LabelDto} from "vegiwise-sdk-typescript/dist/model/@common/label";
import {StorageService} from "src/app/@commons/services/front/storage.service";
import {GrowingStepApi} from "vegiwise-sdk-typescript/dist/sdk/plant/growing-step.api";
import {StringUtils} from "../../../@commons/utils/string-utils";
import {PagingResponse} from "../../../@commons/services/base.service";
import {UpsertGrowingStepRequest} from "vegiwise-sdk-typescript/dist/model/plant/growing-step/upsert-growing-step-req";
import {GrowingStepType} from "vegiwise-sdk-typescript/dist/enums/growing-step-type";

@Component({
  selector: "vw-edit-growing-steps",
  standalone: true,
  imports: [
    CommonModule,
    AppEmulatorComponent,
    EditCultivationHeaderComponent,
    EditCultivationFooterComponent,
    EditGrowingStepComponent,
    ReactiveFormsModule,
    ButtonComponent,
    PlusIconComponent,
  ],
  providers: [CultivationApi, GrowingStepApi],
  templateUrl: "./edit-growing-steps.component.html",
  styleUrls: ["./edit-growing-steps.component.scss"],
})
export class EditGrowingStepsComponent extends AbstractCultivationEditor {
  steps: Array<GrowingStepDto> = [];
  form: FormGroup = this.fb.group({
    stepCtrls: this.fb.array([]),
  });

  get stepCtrls(): FormArray {
    return this.form.get("stepCtrls") as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cultivationApi: CultivationApi,
    protected growingStepApi: GrowingStepApi,
    protected readonly storageService: StorageService
  ) {
    super(router, activatedRoute, cultivationApi, "risks", storageService);
  }

  clearForm() {
    this.stepCtrls.clear();
  }

  addStep(event) {
    this.stepCtrls.push(this.newStepCtrl());
  }

  newStepCtrl(growingStep: GrowingStepDto = null): FormGroup {
    return this.fb.group({
      typeCtrl: "",
      nameCtrl: growingStep
        ? LabelUtils.getInLocale(growingStep.name, this.currentLanguage)
        : "",
      descriptionCtrl: growingStep
        ? LabelUtils.getInLocale(growingStep.description, this.currentLanguage)
        : "",
      durationDaysCtrl: growingStep ? growingStep.durationDays : "",
      stepNumberCtrl: growingStep ? growingStep.stepNumber : "",
      uidCtrl: growingStep ? growingStep.uid : "",
      filesCtrl: growingStep ? (growingStep.files ? growingStep.files : null) : []
    });
  }

  ngOnInit() {
    super.onInit();
  }


  loadCultivation() {
    super.loadCultivation();
    if (StringUtils.isUUID(this.cultivationUid)) {
      this.growingStepApi
          .list({cultivationUid: this.cultivationUid}, 0, 15, this.currentLanguage)
          .then((p: PagingResponse<GrowingStepDto>) => {
            this.steps = p.results.sort((step1, step2) =>  step1.stepNumber - step2.stepNumber);
            this.mapGrowingStepsToForm();
          });
    }
  }

  save($event) {
    if (this.form.valid) {
      this.mapFormToGrowingSteps();
      this.growingStepApi.upsertMany(this.steps.map((p: GrowingStepDto) => {
        //map GrowingStepDto to upsertGrowingStepDto
        const upserGrowingStepDto: UpsertGrowingStepRequest = new UpsertGrowingStepRequest();

        upserGrowingStepDto.uid = p.uid;
        upserGrowingStepDto.description = p.description;
        upserGrowingStepDto.type = p.type || GrowingStepType.PICTURE;
        upserGrowingStepDto.name = p.name;
        upserGrowingStepDto.cultivationUid = this.cultivation.uid
        upserGrowingStepDto.files = p.files;
        upserGrowingStepDto.durationDays = p.durationDays;
        upserGrowingStepDto.stepNumber = p.stepNumber

        return upserGrowingStepDto;
      })).then((res) => {
        this.steps = res;
      });
    }
  }

  deleteStep(i) {
    this.growingStepApi.deleteByUid(this.steps[i].uid).then((uid) => {
      this.stepCtrls.clear();
      this.steps.splice(i, 1);
      this.mapGrowingStepsToForm();
    })
  }


  mapGrowingStepsToForm() {
    if (this.steps) {
      this.steps.forEach((step: GrowingStepDto) => {
        this.stepCtrls.push(this.newStepCtrl(step));
      });
    }
  }

  mapFormToGrowingSteps(): GrowingStepDto[] {
    const backedUpSteps: GrowingStepDto[] = this.steps || [];
    this.steps = this.stepCtrls.controls.map(
        (ctrl, index: number) => {
          const gs: GrowingStepDto = new GrowingStepDto();
          const uid = ctrl.get("uidCtrl").value;
          const existingGrowingStep = backedUpSteps.find(
              (gs) => gs.uid === uid
          );
          gs.name = (existingGrowingStep ? existingGrowingStep.name : new LabelDto()) || new LabelDto();
          gs.description = (existingGrowingStep ? existingGrowingStep.description : new LabelDto()) || new LabelDto();
          gs.name[this.currentLanguage || LabelUtils.DEFAULT_LANGUAGE] =
              ctrl.get('nameCtrl').value;
          gs.description[this.currentLanguage || LabelUtils.DEFAULT_LANGUAGE] =
              ctrl.get('descriptionCtrl').value;
          if(ctrl.get('durationDaysCtrl').value) {
            gs.durationDays = Number.parseInt(ctrl.get('durationDaysCtrl').value);
          }
          gs.stepNumber = index;
          /*if(ctrl.get("stepNumberCtrl").value && Number.isInteger(ctrl.get("stepNumberCtrl").value)) {
            gs.stepNumber = index;//Number.parseInt(ctrl.get("stepNumberCtrl").value);
          }*/
          gs.files = ctrl.get('filesCtrl').value;
          if(uid) {
            gs.uid = uid;
          }
          return gs;
        }
    );
    return this.steps;
  }

  mapFormToCultivation(): CultivationDto {
    return undefined;
  }

  mapCultivationToForm() {
  }

  putGrowingStepUp(i: number) {
    const tmpSteps:GrowingStepDto[]  = this.mapFormToGrowingSteps();
    if (i > 0) { // Check if it's not the first element
      let temp: GrowingStepDto = tmpSteps[i];
      tmpSteps[i] = tmpSteps[i - 1];
      tmpSteps[i - 1] = temp;
    }
    this.stepCtrls.clear();
    this.steps = tmpSteps;
    this.mapGrowingStepsToForm();
  }

  putGrowingStepDown(i: number) {
    const tmpSteps: GrowingStepDto[] = this.mapFormToGrowingSteps();
    if (i < tmpSteps.length - 1) { // Check if it's not the last element
      let temp: GrowingStepDto = tmpSteps[i];
      tmpSteps[i] = tmpSteps[i + 1];
      tmpSteps[i + 1] = temp;
    }
    this.stepCtrls.clear();
    this.steps = tmpSteps;
    this.mapGrowingStepsToForm();
  }
}
