import { Component, Input, OnInit } from "@angular/core";
import { LabelPipe } from "../../pipes/LabelPipe";
import { CultivationItemActionComponent } from "../cultivation-item-action/cultivation-item-action.component";
import { NgIf, NgTemplateOutlet } from "@angular/common";
import { CultivationDto } from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import { FileUtils } from "../../utils/file-utils";
import {DmsService} from "../../services/vegiwise/dms.service";

@Component({
  selector: "vw-cultivation-item",
  templateUrl: "./cultivation-item.component.html",
  styleUrls: ["./cultivation-item.component.scss"],
  standalone: true,
  providers: [DmsService],
  imports: [NgIf, CultivationItemActionComponent, LabelPipe, NgTemplateOutlet],
})
export class CultivationItemComponent implements OnInit {
  @Input() cultivation: CultivationDto;
  isPendingAction: boolean = false;
  cultivationPictureUrl = "";
  backgroundColor: string;
  // TODO: determine the color of the text based on the background/image color
  textColor = "text-black";

  constructor(private dmsService: DmsService) {}

  ngOnInit(): void {
    if (this.cultivation) {
      this.isPendingAction = this.cultivation?.risks?.length > 0;
      //this.cultivationPictureUrl = './assets/cards/Product59.png';
      const fileUid: string = FileUtils.getFileUidFromCollection(this.cultivation.files,  null );
      if (fileUid) {
        console.log(fileUid);
        this.cultivationPictureUrl = `url(${this.dmsService.getFileUrl(fileUid, null, 300)})`;
        console.log(this.cultivationPictureUrl);
        this.backgroundColor = this.cultivation.backgroundColor;
      }
    }
  }
}
