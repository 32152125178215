<vw-default-layout [bodyTemplate]="bodyT">
    <ng-template #bodyT>
        <div class="h-screen">
            <vw-app-header-tabs [tabs]="tabs"> </vw-app-header-tabs>
            <router-outlet></router-outlet>
        </div>
    </ng-template>
</vw-default-layout>


