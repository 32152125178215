import { ActivatedRoute, Params, Router } from "@angular/router";
import { CultivationDto } from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import { StringUtils } from "../../../@commons/utils/string-utils";
import { CultivationApi } from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation.api";
import { LabelUtils } from "../../../@commons/utils/label-utils";
import { FormGroup, UntypedFormGroup, ValidationErrors } from "@angular/forms";
import { StorageService } from "src/app/@commons/services/front/storage.service";
import {ExternalFileDto} from "vegiwise-sdk-typescript/dist/model/@common/external-file";

export abstract class AbstractCultivationEditor {
  public currentLanguage: string = LabelUtils.DEFAULT_LANGUAGE;
  public cultivationUid: string;
  public cultivation: CultivationDto = new CultivationDto();
  public mode: "CREATE" | "UPDATE";
  public form: FormGroup;


  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cultivationApi: CultivationApi,
    protected nextPageUrl: string,
    protected readonly storageService: StorageService
  ) {}

  onInit() {
    this.activatedRoute.parent.params.subscribe((params: Params) => {
      this.cultivationUid = params["cultivationUid"];
      this.mode = StringUtils.isUUID(this.cultivationUid) ? "UPDATE" : "CREATE";
      this.nextPageUrl = `cultivations/${this.cultivationUid}/${this.nextPageUrl}`;
      this.loadCultivation();
    });
  }

  abstract clearForm();
  abstract mapCultivationToForm();
  abstract mapFormToCultivation(): CultivationDto;

  loadCultivation() {
    if (StringUtils.isUUID(this.cultivationUid)) {
      this.cultivationApi
        .findByUid(this.cultivationUid)
        .then((cultivation: CultivationDto) => {
          this.cultivation = cultivation;
          this.mapCultivationToForm();
        });
    }
  }

  save($event) {
    this.saveInLocalStorage();
    if (this.form.valid) {
      this.cultivationApi
        .upsert(this.cultivation)
        .then((res: CultivationDto) => {
          this.cultivation = res;
          if (StringUtils.isUUID(this.cultivationUid) === false) {
            this.cultivationUid = res.uid;
            this.changeParamValue("cultivationUid", res.uid);
          }
        });
    } else {
      console.error("Form is not valid");
      console.error(
        AbstractCultivationEditor.validationErrorsToErros(this.form)
      );
      console.error(this.form);
    }
  }

  gotToNextPage($event) {
    this.saveInLocalStorage();
    this.router.navigate([this.nextPageUrl]);
  }

  changeEditingLanguage(language: string) {
    this.saveInLocalStorage();
    this.currentLanguage = language;
    this.mapCultivationToForm();
  }

  saveInLocalStorage() {
    this.mapFormToCultivation();
    this.storageService.setItem(
      this.cultivation.uid ?? "cultivation",
      JSON.stringify(this.cultivation)
    );
  }

  static validationErrorsToErros(formGroup: UntypedFormGroup): any {
    const errors = {};
    Object.keys(formGroup.controls).forEach((key) => {
      const controlErrors: ValidationErrors = formGroup.get(key).errors;
      if (controlErrors != null) {
        const constraints = [];
        Object.keys(controlErrors).forEach((keyError) => {
          if (controlErrors[keyError]) {
            constraints.push(this.mapKeyErrorToError(keyError));
          }
        });
        if (constraints.length) {
          errors[key] = constraints;
        }
      }
    });
    return errors;
  }

  private static mapKeyErrorToError(keyError): string {
    switch (keyError) {
      case "requiredTrue":
        return "ISNOTEMPTY";
      case "required":
        return "ISNOTEMPTY";
      case "dateIsNotSameOrBefore":
        return "MAXDATE";
      case "dateIsNotBefore":
        return "MAXDATE";
      case "dateIsNotSameOrAfter":
        return "MINDATE";
      case "dateIsNotAfter":
        return "MINDATE";
      case "dateInvalid":
        return "ISDATE";
      case "min":
        return "MIN";
      case "max":
        return "MAX";
      case "email":
        return "ISEMAIL";
      case "minLength":
        return "MINLENGTH";
      case "maxLength":
        return "MAXLENGTH";
      case "pattern":
        return "MATCHES";
      default:
        return keyError;
    }
  }

  changeParamValue(paramName: string, newValue: string): void {
    // Get the current route snapshot
    const currentRoute = this.activatedRoute.snapshot;

    // Get the current parameters
    const currentParams = { ...currentRoute.params };
    // Update the parameter with the new value
    currentParams[paramName] = newValue;

    // Build a new URL tree with the updated parameters
    const updatedUrlTree = this.router.createUrlTree(
      [currentRoute.routeConfig.path, currentParams],
      {
        queryParams: currentRoute.queryParams,
        fragment: currentRoute.fragment,
      }
    );

    // Navigate to the updated URL
    //this.router.navigateByUrl(updatedUrlTree);
  }
}
