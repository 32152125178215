<div class="mt-[20px] space-y-[15px] ">
    <div class="flex items-center gap-x-[15px]">
        <div *ngIf="number" class="h-[32px] w-[32px] flex items-center justify-center bg-[#FA9B1B] text-white text-base leading-6 rounded-full">
            {{number}}
        </div>

        <p class="text-[17px] font-medium left-7">{{label | translate}}</p>
    </div>
    <div class="mb-2.5">
        <ng-content></ng-content>
    </div>
</div>