import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppEmulatorComponent } from "../../../@commons/components/app-emulator/app-emulator.component";
import { EditCultivationHeaderComponent } from "../@commons/edit-cultivation-header/edit-cultivation-header.component";
import { EditCultivationFooterComponent } from "../@commons/edit-cultivation-footer/edit-cultivation-footer.component";
import { AbstractCultivationEditor } from "../@abstract/abstract-cultivation-editor";
import { ActivatedRoute, Router } from "@angular/router";
import { CultivationApi } from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation.api";
import { FormBuilder } from "@angular/forms";
import { StorageService } from "src/app/@commons/services/front/storage.service";
import { CultivationDto } from "vegiwise-sdk-typescript/dist/model/@common/cultivation";

@Component({
  selector: "vw-edit-risks",
  standalone: true,
  imports: [
    CommonModule,
    AppEmulatorComponent,
    EditCultivationHeaderComponent,
    EditCultivationFooterComponent,
  ],
  templateUrl: "./edit-risks.component.html",
  providers: [CultivationApi],
  styleUrls: ["./edit-risks.component.scss"],
})
export class EditRisksComponent extends AbstractCultivationEditor {
  constructor(
    private fb: FormBuilder,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cultivationApi: CultivationApi,
    protected readonly storageService: StorageService
  ) {
    super(router, activatedRoute, cultivationApi, "global", storageService);
  }

  clearForm() {}

  save($event) {
    this.mapFormToCultivation();
  }

  mapCultivationToForm() {}

  mapFormToCultivation(): CultivationDto {
    return this.cultivation;
  }
}
