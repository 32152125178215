import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'vw-cultivation-item-action',
    templateUrl: './cultivation-item-action.component.html',
    styleUrls: ['./cultivation-item-action.component.scss'],
    host: { 'class': '' },
    standalone: true
})
export class CultivationItemActionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
