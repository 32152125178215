import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BulletLabelComponent} from "../../../../@commons/components/@forms/bullet-label/bullet-label.component";
import {FileUploadComponent} from "../../../../@commons/components/@forms/file-upload/file-upload.component";
import {TextInputComponent} from "../../../../@commons/components/@generic/inputs/text-input/text-input.component";
import {TextareaComponent} from "../../../../@commons/components/@generic/inputs/text-area/text-area.component";
import {AbstractControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {SelectComponent} from "../../../../@commons/components/@generic/inputs/select/select.component";
import {CultivationPeriodType} from "vegiwise-sdk-typescript/dist/enums/cultivation-period-type";
import * as moment from "moment";
import {TranslateModule} from "@ngx-translate/core";
import {
    ButtonSelectComponent
} from "../../../../@commons/components/@generic/inputs/button-select/button-select.component";
import {ButtonComponent} from "../../../../@commons/components/@generic/buttons/button/button.component";
import {TrashIconComponent} from "../../../../@commons/components/@generic/icons/trash-icon/trash-icon.component";
import {LeafIconComponent} from "../../../../@commons/components/@generic/icons/leaf-icon/leaf-icon.component";
import {
    RoundedButtonComponent
} from "../../../../@commons/components/@generic/buttons/rounded-button/rounded-button.component";
import {CultivationPeriodDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation-period";
import {FormGroupComponent} from "../../../../@commons/components/@generic/inputs/form-group/form-group.component";

@Component({
    selector: 'vw-edit-period',
    standalone: true,
    imports: [CommonModule, BulletLabelComponent, FileUploadComponent, TextInputComponent, TextareaComponent,
        ReactiveFormsModule, SelectComponent, TranslateModule, ButtonSelectComponent, ButtonComponent, TrashIconComponent, LeafIconComponent, RoundedButtonComponent, FormGroupComponent],
    templateUrl: './edit-period.component.html',
    styleUrls: ['./edit-period.component.scss']
})
export class EditPeriodComponent {
    @Input() formGroup: any;
    @Input() periodNumber: number;
    @Input() totalPeriods: number;
    @Output() deleteClicked: EventEmitter<any> = new EventEmitter<any>();
    public cultivationPeriodTypes: CultivationPeriodType[] = []
    months = moment.months().map(m=> m.toUpperCase());

    protected readonly CultivationPeriodType = CultivationPeriodType;

    constructor() {
        this.cultivationPeriodTypes = [CultivationPeriodType.SEEDLING, CultivationPeriodType.PLANTING,
            CultivationPeriodType.HARVEST];
    }

    deletePeriod() {
        this.deleteClicked.emit(this.formGroup);
    }

}
