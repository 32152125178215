<div class="relative">
  <!-- content -->
  <form [formGroup]="form">
    <div
      class="py-[54px] max-w-[700px] mx-auto h-[calc(100vh-77px-72px)] overflow-y-auto scrollBarHidden"
    >
      <vw-edit-cultivation-header
        [label]="'CULTIVATION.PERIODS.TITLE'"
        [description]="'CULTIVATION.PERIODS.DESCRIPTION'"
        [selectedCultivation]="cultivation"
      >
      </vw-edit-cultivation-header>
      <vw-button
        [look]="'SECONDARY'"
        (click)="addPeriod($event)"
        [buttonClasses]="'w-full mt-3 mb-3'"
        [label]="'CULTIVATION.PERIODS.ADD'"
      >
        <vw-plus-icon class="btn-icon"></vw-plus-icon>
      </vw-button>

      <div class="space-y-[15px]" formArrayName="periodCtrls">
        <ng-container
          *ngFor="let periodCtrl of periodCtrls.controls; index as i"
        >
          <div [formGroupName]="i">
            <vw-edit-period
              [periodNumber]="i + 1"
              [formGroup]="periodCtrl"
              (deleteClicked)="onDelete(i)"
              [totalPeriods]="periodCtrls.controls.length"
            ></vw-edit-period>
          </div>
        </ng-container>
      </div>
    </div>
  </form>

  <!-- bottom buttons -->
  <vw-edit-cultivation-footer
    (save)="save($event)"
    (changeLanguage)="changeEditingLanguage($event)"
    (next)="gotToNextPage($event)"
  >
  </vw-edit-cultivation-footer>
</div>
