import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Input, Output, EventEmitter, Component } from '@angular/core';
import { BaseEditableComponent } from './base-editable-component';

@Component({
    template: ''
})
export abstract class BaseInput<T> extends BaseEditableComponent implements ControlValueAccessor {
    // display parameters
    @Input()
    private _editMode = true; // set input to form-control-plaintext
    public get editMode() {
        return this._editMode;
    }
    public set editMode(value) {
        this._editMode = value;
    }
    @Input() required = false;
    @Input() onlyValue = false;
    @Input() min: number = 0;
    @Input() step: number = 0;
    @Input() disabled = false;
    @Input() inputClasses = '';
    // global parameters
    @Input() prefix = ''; // to prefix all internationalizations
    @Input() id: string = 'input' + ((Math.random() * 1000) + 0);
    @Input() label = '';
    @Input() labelClasses = '';
    @Input() inline = false;
    @Input() formGroupClasses = '';
    @Input() type = 'text'; // final type of the input
    @Input() name: string = 'input' + ((Math.random() * 1000) + 0);
    @Input() placeholder = '';
    @Input() toolTip: string;
    @Input() format: RegExp; // use to format the content & show errors
    // error: two ways to put the component in red :
    // 1) set error to 'true'
    // 2) give an object with all fields in error & the field to look for
    @Input() error = false;
    @Input() errors: any = {};
    @Input() errorField = '';
    @Input() errorLabel = '';
    @Input() errorFieldLabel = '';
    @Input() showDefaultErrorLabel = true;
    // success
    @Input() success = false;
    @Input() successLabel: string;
    // value
    @Input() value: any = ''; // value binded to input
    @Output() valueChange: EventEmitter<T> = new EventEmitter<T>();
    @Output() enterPressed: EventEmitter<void> = new EventEmitter<void>();

    onChange = (v: T) => { };
    onTouched = () => { };

    constructor(
        private control: NgControl
    ) {
        super();
        if (this.control) {
            this.control.valueAccessor = this;
        }
    }

    // CONTROL VALUE ACCESSOR METHODS
    public get invalid(): boolean {
        return this.control ? this.control.invalid : false;
    }

    public get showError(): boolean {
        if (!this.control) {
            return false;
        }
        const { dirty, touched } = this.control;
        return this.invalid ? (dirty || touched) : false;
    }

    // Allows Angu&lar to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(v: T): void {
        this.value = v;
        this.onChange(this.value);
    }
    // Allows Angular to register a function to call when the model (rating) changes.
    // Save the function as a property to call later here.
    registerOnChange(fn: (v: T) => void): void {
        this.onChange = fn;
    }
    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    // Allows Angular to disable the input.
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    sendChange() {
        this.valueChange.emit(this.value);
        this.onChange(this.value);
    }

    onKeyPress(event): void {

        if (event.key === 'Enter') {
            this.enterPressed.emit();
        }
    }
}