import {Component, OnInit} from '@angular/core';
import { CultivationItemComponent } from '../../@commons/components/cultivation-item/cultivation-item.component';
import { NgFor } from '@angular/common';
import {Router, RouterModule} from '@angular/router';
import { CultivationDto } from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { CriteriaCreatorComponent } from './criteria-creator/criteria-creator.component';
import { NotificationCreatorComponent } from './notification-creator/notification-creator.component';

@Component({
    selector: 'vw-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    standalone: true,
  imports: [NgFor, CultivationItemComponent, RouterModule, NotificationsListComponent, CriteriaCreatorComponent, NotificationCreatorComponent],
})
export class HomePageComponent implements OnInit {
  cultivations: CultivationDto[] = [];
  cultivationPairs: Array<CultivationDto[]> = [];

  constructor(private router: Router) {

  }

  ngOnInit() {
  }

}
