

<ng-template #defaultBody >
    DEFAULT BODY
</ng-template>

<main class="grid grid-cols-[0.13125fr_0.86875fr] max-h-screen gap-x-0.5 max-w-[2760px] mx-auto bg-[#EAECF2] overflow-hidden">

    <vw-app-menu class="py-[45px] bg-white h-full"></vw-app-menu>

    <ng-container  [ngTemplateOutlet]="bodyTemplate ? bodyTemplate : defaultBody">
    </ng-container>
</main>
