<div
  class="sticky bottom-0 grid grid-cols-3 text-[18px] leading-[24px] font-normal text-center text-white"
>
  <div
    style="background-color: #2aba66"
    class="absolute min-w-fit bottom-[75px]"
    *ngIf="isLanguageSelectorOpen"
  >
    <ul>
      <li *ngFor="let language of availableLanguages">
        <button
          [style.background-color]="'#475569'"
          (click)="onChangeLanguage(language)"
          class="px-[24px] py-[24px] flex items-center justify-center gap-x-2 w-full"
        >
          <span>
            <ng-content></ng-content>
          </span>
          <span>{{
            "COMMON.LANGUAGES." + language.toUpperCase() | translate
          }}</span>
        </button>
      </li>
    </ul>
  </div>
  <vw-footer-button
    [label]="'COMMON.LANGUAGES.' + currentLanguage.toUpperCase() | translate"
    class="w-full"
    [backgroundColor]="'#475569'"
    (click)="isLanguageSelectorOpen = !isLanguageSelectorOpen"
  >
    <vw-language-icon></vw-language-icon>
  </vw-footer-button>
  <vw-footer-button
    [label]="'Save'"
    class="w-full"
    [backgroundColor]="'#30A156'"
    (click)="onSave($event)"
  >
    <vw-fat-arrow-icon></vw-fat-arrow-icon>
  </vw-footer-button>
  <vw-footer-button
    [label]="'Next'"
    class="w-full"
    [backgroundColor]="'#FA9B1B'"
    (click)="onNext($event)"
  >
    <vw-right-long-arrow></vw-right-long-arrow>
  </vw-footer-button>
</div>
