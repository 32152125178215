import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'vw-bullet-label',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './bullet-label.component.html',
  styleUrls: ['./bullet-label.component.scss']
})
export class BulletLabelComponent {

  @Input() number: string;
  @Input() label: string;

}
