import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AppEmulatorComponent} from "../../../@commons/components/app-emulator/app-emulator.component";
import {EditCultivationHeaderComponent} from "../@commons/edit-cultivation-header/edit-cultivation-header.component";
import {ColorPickerComponent} from "../../../@commons/components/@generic/inputs/color-picker/color-picker.component";
import {EditCultivationFooterComponent} from "../@commons/edit-cultivation-footer/edit-cultivation-footer.component";
import {TextInputComponent} from "../../../@commons/components/@generic/inputs/text-input/text-input.component";
import {BulletLabelComponent} from "../../../@commons/components/@forms/bullet-label/bullet-label.component";
import {AbstractCultivationEditor} from "../@abstract/abstract-cultivation-editor";
import {ActivatedRoute, Router} from "@angular/router";
import {
    PictureUploaderComponent
} from "../../../@commons/components/@forms/picture-uploader/picture-uploader.component";
import {CultivationApi} from "vegiwise-sdk-typescript/dist/sdk/plant/cultivation.api";
import {
    FormBuilder,
    FormControl,
    ReactiveFormsModule,
    Validators,
} from "@angular/forms";
import {LabelUtils} from "../../../@commons/utils/label-utils";
import {TextareaComponent} from "../../../@commons/components/@generic/inputs/text-area/text-area.component";
import {LabelDto} from "vegiwise-sdk-typescript/dist/model/@common/label";
import {StorageService} from "src/app/@commons/services/front/storage.service";
import {CultivationDto} from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import {ExternalFileDto} from "vegiwise-sdk-typescript/dist/model/@common/external-file";

@Component({
    selector: "vw-edit-general-data",
    standalone: true,
    imports: [
        CommonModule,
        AppEmulatorComponent,
        EditCultivationHeaderComponent,
        ColorPickerComponent,
        EditCultivationFooterComponent,
        TextInputComponent,
        TextareaComponent,
        BulletLabelComponent,
        PictureUploaderComponent,
        ReactiveFormsModule,
    ],
    templateUrl: "./edit-general-data.component.html",
    providers: [CultivationApi],
    styleUrls: ["./edit-general-data.component.scss"],
})
export class EditGeneralDataComponent extends AbstractCultivationEditor implements OnInit {
    cultivationCoverPicture: ExternalFileDto;

    constructor(
        private fb: FormBuilder,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected cultivationApi: CultivationApi,
        protected readonly storageService: StorageService
    ) {
        super(router, activatedRoute, cultivationApi, "periods", storageService);
    }

    ngOnInit() {
        super.onInit();
        this.initForm();
        this.mapCultivationToForm();
    }

    initForm() {
        this.form = this.fb.group({
            nameCtrl: new FormControl("", [Validators.required]),
            descriptionCtrl: new FormControl(""),
            backgroundColorCtrl: new FormControl(""),
        });
    }

    clearForm() {
        this.form.reset();
    }

    onColorChanged(color: string) {
        this.cultivation.backgroundColor = color;
    }

    mapCultivationToForm() {
        if (this.cultivation) {
            this.completeEmptyFieldsInCultivation();
            this.form.controls.nameCtrl.setValue(
                LabelUtils.getInLocale(this.cultivation.name, this.currentLanguage)
            );
            this.form.controls.descriptionCtrl.patchValue(
                LabelUtils.getInLocale(
                    this.cultivation.description,
                    this.currentLanguage
                )
            );
            this.form.controls.backgroundColorCtrl.patchValue(
                this.cultivation.backgroundColor
            );

            if(this.cultivation && this.cultivation.files) {
                this.cultivationCoverPicture = this.cultivation.files.find((file: ExternalFileDto) => true); //TODO add type cover ?
            }
        }
    }

    mapFormToCultivation(): CultivationDto {
        this.completeEmptyFieldsInCultivation();
        this.cultivation.name[this.currentLanguage || LabelUtils.DEFAULT_LANGUAGE] = this.form.controls.nameCtrl.value;
        this.cultivation.description[this.currentLanguage || LabelUtils.DEFAULT_LANGUAGE] = this.form.controls.descriptionCtrl.value;
        this.cultivation.backgroundColor = this.form.controls.backgroundColorCtrl.value;
        if(this.cultivationCoverPicture) {
          this.cultivation.files = [this.cultivationCoverPicture];
        } else {
          this.cultivation.files = [];
        }
        return this.cultivation;
    }

    completeEmptyFieldsInCultivation(): CultivationDto {
        if (!this.cultivation.name) {
            this.cultivation.name = new LabelDto();
        }
        if (!this.cultivation.description) {
            this.cultivation.description = new LabelDto();
        }
        return this.cultivation;
    }
}
