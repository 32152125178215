<svg width="25" height="24" viewBox="0 0 25 24" fill="none"
     xmlns="http://www.w3.org/2000/svg">
    <path
            d="M22.5 6.75C22.9142 6.75 23.25 6.41421 23.25 6C23.25 5.58579 22.9142 5.25 22.5 5.25V6.75ZM14.5 5.25C14.0858 5.25 13.75 5.58579 13.75 6C13.75 6.41421 14.0858 6.75 14.5 6.75V5.25ZM19.25 2C19.25 1.58579 18.9142 1.25 18.5 1.25C18.0858 1.25 17.75 1.58579 17.75 2H19.25ZM17.75 10C17.75 10.4142 18.0858 10.75 18.5 10.75C18.9142 10.75 19.25 10.4142 19.25 10H17.75ZM22.5 5.25H18.5V6.75H22.5V5.25ZM18.5 5.25H14.5V6.75H18.5V5.25ZM17.75 2V6H19.25V2H17.75ZM17.75 6V10H19.25V6H17.75Z"
            fill="#30A156"
    />
    <path
            d="M12 3C7.52166 3 5.28249 3 3.89124 4.39124C2.5 5.78249 2.5 8.02166 2.5 12.5C2.5 16.9783 2.5 19.2175 3.89124 20.6088C5.28249 22 7.52166 22 12 22C16.4783 22 18.7175 22 20.1088 20.6088C21.5 19.2175 21.5 16.9783 21.5 12.5V12"
            stroke="#30A156"
            stroke-width="1.5"
            stroke-linecap="round"
    />
    <path
            d="M5.5 21C9.70998 16.2487 14.4412 9.9475 21.5 14.6734"
            stroke="#30A156"
            stroke-width="1.5"/>
</svg>