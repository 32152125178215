<div
        [style.background-color]="backgroundColor"
        [style.cursor]="'pointer'"
        [style.background-image]="pictureUrl"
        [style.background-size]="'cover'"
        (click)="fileClicked.emit($event)"
        class="h-[220px] w-full px-2.5 py-3.5 bg-no-repeat bg-cover bg-center rounded-[10px] overflow-hidden max-w-[160px] border border-solid border-gray-300"
>
    <div class="flex justify-end">
        <div class="flex items-center gap-x-0.5" *ngIf="selectedImage || pictureUrl">
            <vw-rounded-button [look]="'ALERT'" (click)="deleteClicked.emit()">
                <vw-trash-icon class="btn-icon"></vw-trash-icon>
            </vw-rounded-button>
        </div>
    </div>
    <div class="flex justify-center items-center h-full">
        <div *ngIf="!selectedImage && !pictureUrl" class="flex flex-col items-center justify-center">
            <i class="fas fa-image text-4xl"></i>
        </div>
    </div>
</div>