<div class="m-5 px-1 py-6 bg-[#FFFFFF] rounded-md">
        <div class="flex flex-row">
            <div class="mx-2 basis-1/4">
                <h3>Messages</h3>
                <!-- Search bar -->
                <div class="relative py-2">
                    <label for="search" class="block text-sm font-medium leading-6 text-gray-900"></label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2">
                            <span class="text-gray-500 sm:text-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                </svg>
                            </span>
                        </div>
                        <input type="text" name="search" id="search" class="block w-full rounded-md border border-gray-300 py-1.5 pl-7 pr-20 text-gray-900 bg-gray-100 focus:bg-gray-100 focus:border-gray-500 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none focus:ring-1 focus:ring-gray-500" placeholder="Search by name...">
                    </div>
                </div>
                <!-- conv-perso -->
                <div class="my-2 px-2">
                    <!-- <div class="h-[1px] bg-[#E1E1E1] w-full my-[20px]"></div> -->
                    <div class="flex gap-x-4">
                        <div class="min-w-[36px] flex justify-center relative">
                            <img src="/assets/imgs/avatar.png" alt="avatar" class="absolute top-0 w-[36px] h-[36px] rounded-full" />
                        </div>
                        <div>
                            <div>
                                <div class="flex items-center gap-x-4 pb-1">
                                    <p class="text-sm">Jonathan Smith</p>
                                    <p class="text-[#475569] uppercase text-xs leading-6 font-medium tracking-[1.5px]">
                                        10:40 AM
                                    </p>
                                </div>

                                <p class="text-xs leading-6 truncate w-36">I think this is great vegetable, What do you think about it guys?</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- conv-perso -->
                <div class="my-2 px-2">
                    <!-- <div class="h-[1px] bg-[#E1E1E1] w-full my-[20px]"></div> -->
                    <div class="flex gap-x-4">
                        <div class="min-w-[36px] flex justify-center relative">
                            <img src="/assets/imgs/avatar.png" alt="avatar" class="absolute top-0 w-[36px] h-[36px] rounded-full" />
                        </div>
                        <div>
                            <div>
                                <div class="flex items-center gap-x-4 pb-1">
                                    <p class="font-sm">Jonathan Smith</p>
                                    <p class="text-[#475569] uppercase text-xs leading-6 font-medium tracking-[1.5px]">
                                        10:40 AM
                                    </p>
                                </div>

                                <p class="text-xs leading-6 truncate w-36">I think this is great vegetable, What do you think about it guys?</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- conv-perso -->
                <div class="my-2 px-2">
                    <!-- <div class="h-[1px] bg-[#E1E1E1] w-full my-[20px]"></div> -->
                    <div class="flex gap-x-4">
                        <div class="min-w-[36px] flex justify-center relative">
                            <img src="/assets/imgs/avatar.png" alt="avatar" class="absolute top-0 w-[36px] h-[36px] rounded-full" />
                        </div>
                        <div>
                            <div>
                                <div class="flex items-center gap-x-4 pb-1">
                                    <p class="font-sm">Jonathan Smith</p>
                                    <p class="text-[#475569] uppercase text-xs leading-6 font-medium tracking-[1.5px]">
                                        10:40 AM
                                    </p>
                                </div>

                                <p class="text-xs leading-6 truncate w-36">I think this is great vegetable, What do you think about it guys?</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- conv-perso -->
                <div class="my-2 px-2">
                    <!-- <div class="h-[1px] bg-[#E1E1E1] w-full my-[20px]"></div> -->
                    <div class="flex gap-x-4">
                        <div class="min-w-[36px] flex justify-center relative">
                            <img src="/assets/imgs/avatar.png" alt="avatar" class="absolute top-0 w-[36px] h-[36px] rounded-full" />
                        </div>
                        <div>
                            <div>
                                <div class="flex items-center gap-x-4 pb-1">
                                    <p class="font-sm">Jonathan Smith</p>
                                    <p class="text-[#475569] uppercase text-xs leading-6 font-medium tracking-[1.5px]">
                                        10:40 AM
                                    </p>
                                </div>

                                <p class="text-xs leading-6 truncate w-36">I think this is great vegetable, What do you think about it guys?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <div class="col-span-3 mx-2 basis-3/4">
                <!-- entete -->
                <div class="flex gap-x-4">
                        <div class="min-w-[36px] flex justify-center relative">
                            <img src="/assets/imgs/avatar.png" alt="avatar" class="absolute top-0 w-[36px] h-[36px] rounded-full" />
                        </div>
                        <div>
                            <div>
                                <p class="text-m">Ceasar Brandon</p>
                                <p class="text-xs">Last seen 5 min ago</p>
                            </div>
                        </div>
                </div>
                <!-- ligne -->
                <div class="h-[1px] bg-[#E1E1E1] w-full my-[20px]"></div>

                <!-- conversations -->
                <div class="mx-2">
                    <div class="flex flex-col space-y-2 h-96 overflow-y-auto">
                        <!-- Message de l'interlocuteur -->
                        <div class="flex items-center justify-start">
                            <div class="flex pb-2">
                                <div class="mr-2 min-w-[36px] self-end">
                                    <img src="/assets/imgs/avatar.png" alt="avatar" class="top-0 w-[36px] h-[36px] rounded-full" />
                                </div>
                                <div>
                                    <p>Ceasar</p>
                                    <div>
                                        <!-- 1rst mess -->
                                        <div class="mb-2">
                                            <div class="rounded-md border border-gray-300 w-2/3 p-4">
                                                <p>
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo placeat officiis id iusto et consectetur dolores maxime ipsum similique, quo vel inventore ut vitae, temporibus at quas impedit! Rerum, reprehenderit.
                                                </p>
                                            </div>
                                            <p>10:36 AM</p>
                                        </div>
                                        
                                        <!-- 2nd mess -->
                                        <div class="">
                                            <div class="rounded-md border border-gray-300 w-2/3 p-4 ">
                                                <p>
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo placeat officiis id iusto et consectetur dolores maxime ipsum similique,
                                                </p>
                                            </div>
                                            <p>10:37 AM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Vos réponses -->
                        <div class="pr-4 pb-2">
                            <!-- Moi 1 -->
                            <div class="flex flex-col items-end mb-2">
                                <div class="rounded-md bg-[#30A156] text-[#FFFFFF] border border-gray-300 w-2/3 p-4">
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo placeat officiis id iusto et consectetur dolores maxime ipsum similique, quo vel inventore ut vitae, temporibus at quas impedit! Rerum, reprehenderit.
                                    </p>
                                </div>
                                <p>10:36 AM</p>
                            </div>
                            <!-- Moi 2 -->
                            <div class="flex flex-col items-end">
                                <div class="rounded-md bg-[#30A156] text-[#FFFFFF] border border-gray-300 w-2/3 p-4">
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo placeat officiis id iusto et consectetur dolores maxime ipsum similique, quo vel inventore ut vitae, temporibus at quas impedit! Rerum, reprehenderit.
                                    </p>
                                </div>
                                <p>10:36 AM</p>
                            </div>
                        </div>
                        <!-- !!! DATE  -->
                        <div class="flex items-center justify-center m-2">
                            <div class="w-1/6 text-center bg-gray-100 border rounded-full border-gray-300 p-1">
                                <p>3 March</p>
                            </div>
                        </div>

                        <!-- Message de l'interlocuteur -->
                        <div class="flex items-center justify-start">
                            <div class="flex pb-2">
                                <div class="mr-2 pb-2 min-w-[36px] self-end">
                                    <img src="/assets/imgs/avatar.png" alt="avatar" class="top-0 w-[36px] h-[36px] rounded-full" />
                                </div>
                                <div>
                                    <p>Ceasar</p>
                                    <div>
                                        <!-- 1rst mess -->
                                        <div class="mb-2">
                                            <div class="rounded-md border border-gray-300 w-2/3 p-4">
                                                <p>
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo placeat officiis id iusto et consectetur dolores maxime ipsum similique, quo vel inventore ut vitae, temporibus at quas impedit! Rerum, reprehenderit.
                                                </p>
                                            </div>
                                            <p>10:36 AM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- textarea message -->
                <div>
                    <textarea name="message" id="message" cols="20" rows="10" class="block w-full rounded-md p-2 border border-gray-300 max-h-20 text-gray-900 focus:bg-gray-100 focus:border-gray-500 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none focus:ring-1 focus:ring-gray-500" ></textarea>
                </div>
            </div>
        </div>
</div>



