import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {AbstractButton} from "../abstract-button";

@Component({
  selector: 'vw-button',
  standalone: true,
    imports: [CommonModule, TranslateModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent extends AbstractButton implements OnInit, OnChanges {


  ngOnInit() {
    this.computeStyle()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.computeStyle();
  }

  computeStyle() {
    super.computeStyle();
  }


}
