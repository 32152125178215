import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-upload-file-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './upload-file-icon.component.html',
  styleUrls: ['./upload-file-icon.component.scss']
})
export class UploadFileIconComponent {

}
