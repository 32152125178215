<main
  class="min-h-screen h-screen mx-auto flex flex-col justify-between overflow-hidden"
>
  <section class="w-[500px] max-w-[500] mx-auto">
    <div class="h-[64px] w-full"></div>
    <img
      src="/assets/common/vegiwise-logo.png"
      alt="logo"
      class="h-[100px] min-w-[116px] mx-auto"
    />
    <div class="h-[50px] w-full"></div>
    <div class="w-full max-w-full" *ngIf="!isConfirmed">
      <form [formGroup]="form">
        <div class="mb-6">
          <label
            class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"
          >
            {{ "CONFIRM_PASSWORD.FORM.EMAIL.LABEL" | translate }}
          </label>
          <vw-text-input
            [placeholder]="'CONFIRM_PASSWORD.FORM.EMAIL.PLACEHOLDER'"
            [type]="'email'"
            [iconTemplate]="mailIcon"
            formControlName="emailCtrl"
          >
            <ng-template #mailIcon>
              <vw-mail-icon></vw-mail-icon>
            </ng-template>
          </vw-text-input>
        </div>
        <div class="mb-2.5">
          <label
            class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"
          >
            {{ "CONFIRM_PASSWORD.FORM.PASSWORD.LABEL" | translate }}
          </label>
          <vw-text-input
            [placeholder]="'CONFIRM_PASSWORD.FORM.PASSWORD.PLACEHOLDER'"
            [type]="'password'"
            [iconTemplate]="keyIcon"
            formControlName="newPasswordCtrl"
          >
            <ng-template #keyIcon>
              <vw-key-icon></vw-key-icon>
            </ng-template>
          </vw-text-input>
        </div>
        <div class="mb-6">
          <label
            class="block text-[#202939] text-sm leading-6 font-medium mb-2.5"
          >
            {{
              "CONFIRM_PASSWORD.FORM.CODE.LABEL" | translate : { mail: "test" }
            }}
          </label>
          <vw-text-input
            [placeholder]="'CONFIRM_PASSWORD.FORM.CODE.PLACEHOLDER'"
            [type]="'string'"
            [iconTemplate]="companyIcon"
            formControlName="codeCtrl"
          >
            <ng-template #companyIcon>
              <vw-key-icon></vw-key-icon>
            </ng-template>
          </vw-text-input>
          <!-- Error Message -->
          <vw-error-message
            *ngFor="let error of errors"
            [label]="error.label"
            [message]="error.message"
            [fieldName]="error.field"
            [formI18nPath]="'CONFIRM_PASSWORD.FORM'"
          ></vw-error-message>
          <div class="h-[50px] w-full"></div>
          <div class="flex items-center gap-x-[12px]">
            <button
              class="bg-[#30A156] hover:bg-[#30A156]/80 border-[0.5px] border-[#30A156] rounded-[7px] text-base font-medium text-white px-6 py-2.5 w-full"
              (click)="confirmPassword()"
              type="button"
            >
              {{ "CONFIRM_PASSWORD.FORM.SEND" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="isConfirmed">
      <label class="block text-[#202939] text-sm leading-6 font-medium mb-2.5">
        {{ "CONFIRM_PASSWORD.CONFIRMED" | translate }}
      </label>
    </div>
    <div class="h-[64px] w-full"></div>
  </section>
</main>
