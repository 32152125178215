import {LabelDto} from "vegiwise-sdk-typescript/dist/model/@common/label";

export class LabelUtils {

    public static DEFAULT_LANGUAGE: string = 'fr';

    static getInLocale(value: LabelDto, locale: string): string {
        if(value) {
            return value[(locale || this.DEFAULT_LANGUAGE)];
        } else {
            return '';
        }
    }

}