import "./polyfills";

import { enableProdMode, importProvidersFrom } from "@angular/core";
import { AppComponent } from "./app/app.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from "@angular/common/http";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { environment } from "./environments/environment";
import {
  provideRouter,
  withDebugTracing,
  withHashLocation,
} from "@angular/router";
import { APP_ROUTES } from "./app/app.routes";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "/assets/i18n/", ".json");
}
if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(HttpClientModule, BrowserModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: "fr",
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      })
    ),
    provideRouter(APP_ROUTES, withHashLocation()),
    // registering interceptors
    // { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
  ],
}).catch((err) => console.error(err));
