import { Component, Input ,OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from "@angular/router";
import { ArticleDto } from "vegiwise-sdk-typescript/dist/model/@common/article";
import { SocialApi } from "vegiwise-sdk-typescript/dist/sdk/social/social.api";
import { PagingResponse } from 'vegiwise-sdk-typescript/dist/model/pagination/paging-response';
import { ListArticleRequest } from 'vegiwise-sdk-typescript/dist/model/plant/list-article-req';
import { UUID } from "crypto";

@Component({
  selector: 'vw-article',
  standalone: true,
  imports: [
      CommonModule,
      RouterModule,
    ],
  providers: [SocialApi],
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  @Input() articles: ArticleDto[] = [];
  filters: ListArticleRequest;
  
  constructor(
    private router: Router,
    private socialApi: SocialApi,
  ) {}

  ngOnInit(){
    this.loadArticle();
    console.log(this.articles);
  }
  loadArticle() {
    // Créer une instance UUID pour l'ownerUid
    const ownerUid: UUID = 'ff4fd2ef-02bd-4a29-bbd9-5ad10bb66ea3';

    // Créer l'objet payload et initialiser ownerUid
    const payload: ListArticleRequest = {
    name: ownerUid
    };
    
    const lang:string = 'fr';

    this.socialApi.listArticles(payload, lang)
      .then((pagingResponse: PagingResponse<ArticleDto>) => {
        const articles: ArticleDto[] = pagingResponse.results;
        console.log(articles);
      })
      .catch((error) => {
        console.error(error);
      });
    }
}
