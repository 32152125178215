<div class="relative">
  <div
    class="py-[54px] px-[10px] [@media(min-width:1920px)]:px-0 max-w-[978px] mx-auto h-[calc(100vh-77px-72px)] overflow-y-auto scrollBarHidden space-y-[20px]"
  >
    <vw-edit-cultivation-header
      [label]="'Set warning for plant and climatic conditions'"
    ></vw-edit-cultivation-header>

    <div class="mt-[48px]">
      <table
        class="w-full rounded-[12px] overflow-hidden border-collapse border border-[#EAECF2]"
      >
        <thead>
          <tr
            class="grid grid-cols-[18.5%_18.5%_20%_17%_20%_6%] text-white font-medium text-sm leading-6 uppercase"
          >
            <th class="bg-[#475569] py-[9px] border border-[#EAECF2]">risk</th>
            <th class="bg-[#475569] py-[9px] border border-[#EAECF2]">
              default risk
            </th>
            <th class="bg-[#475569] py-[9px] border border-[#EAECF2]">
              criteria
            </th>
            <th class="bg-[#475569] py-[9px] border border-[#EAECF2]">
              actions
            </th>
            <th class="bg-[#475569] py-[9px] border border-[#EAECF2]">info</th>
            <th class="bg-[#475569] py-[9px] border border-[#EAECF2]">
              ind...
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            class="grid grid-cols-[18.5%_18.5%_20%_17%_20%_6%] font-medium text-[#191B20] text-base leading-6 bg-white text-center"
          >
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center border border-[#EAECF2]"
            >
              <select>
                <option value="Fungies">Fungies</option>
              </select>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Default</p>

              <button
                class="w-[38px] h-[22px] rounded-full bg-[#9CA3AF] flex items-center"
              >
                <div
                  class="h-[18px] w-[18px] bg-white rounded-full ml-[2px]"
                ></div>
              </button>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Humidity>50 for 10 days</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Stop watering</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Weather can be dangerous for plants.</p>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <button>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 11.5L6.5 18.5"
                    stroke="#30A156"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.041 6.58278L14.7992 6.69567C12.7873 6.87857 11.7814 6.97002 11.5447 7.62388C11.3079 8.27774 12.0222 8.99198 13.4507 10.4205L14.5795 11.5493C16.008 12.9778 16.7223 13.6921 17.3761 13.4553C18.03 13.2186 18.1214 12.2127 18.3043 10.2008L18.4172 8.95902C18.5273 7.74766 18.5824 7.14198 18.2202 6.77979C17.858 6.4176 17.2523 6.47266 16.041 6.58278Z"
                    stroke="#30A156"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </td>
          </tr>
          <tr
            class="grid grid-cols-[18.5%_18.5%_20%_17%_20%_6%] font-medium text-[#191B20] text-base leading-6 bg-[#F5F6F8] text-center"
          >
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center border border-[#EAECF2]"
            >
              <select disabled>
                <option value="Fungies">Fungies</option>
              </select>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Default</p>

              <button
                class="w-[38px] h-[22px] rounded-full bg-[#30A156] flex items-center justify-end"
              >
                <div
                  class="h-[18px] w-[18px] bg-white rounded-full mr-[2px]"
                ></div>
              </button>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Humidity>50 for 10 days</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Stop watering</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Weather can be dangerous for plants.</p>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <button>
                <svg
                  width="63"
                  height="95"
                  viewBox="0 0 63 95"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="63" height="95" fill="#F5F6F8" />
                  <path
                    d="M37 42L29.5 49.5"
                    stroke="#F26B59"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M26.0447 52.4184L26.4166 47.5839C26.4494 47.158 26.9666 46.9666 27.2687 47.2687L31.7313 51.7313C32.0334 52.0334 31.842 52.5506 31.4161 52.5834L26.5816 52.9553C26.2759 52.9788 26.0212 52.7241 26.0447 52.4184Z"
                    stroke="#F26B59"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </td>
          </tr>
          <tr
            class="grid grid-cols-[18.5%_18.5%_20%_17%_20%_6%] font-medium text-[#191B20] text-base leading-6 bg-white text-center"
          >
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center border border-[#EAECF2]"
            >
              <select>
                <option value="Fungies">Fungies</option>
              </select>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Default</p>

              <button
                class="w-[38px] h-[22px] rounded-full bg-[#9CA3AF] flex items-center"
              >
                <div
                  class="h-[18px] w-[18px] bg-white rounded-full ml-[2px]"
                ></div>
              </button>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Humidity>50 for 10 days</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Stop watering</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Weather can be dangerous for plants.</p>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <button>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 11.5L6.5 18.5"
                    stroke="#30A156"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.041 6.58278L14.7992 6.69567C12.7873 6.87857 11.7814 6.97002 11.5447 7.62388C11.3079 8.27774 12.0222 8.99198 13.4507 10.4205L14.5795 11.5493C16.008 12.9778 16.7223 13.6921 17.3761 13.4553C18.03 13.2186 18.1214 12.2127 18.3043 10.2008L18.4172 8.95902C18.5273 7.74766 18.5824 7.14198 18.2202 6.77979C17.858 6.4176 17.2523 6.47266 16.041 6.58278Z"
                    stroke="#30A156"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </td>
          </tr>
          <tr
            class="grid grid-cols-[18.5%_18.5%_20%_17%_20%_6%] font-medium text-[#191B20] text-base leading-6 bg-white text-center"
          >
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center border border-[#EAECF2]"
            >
              <select>
                <option value="Fungies">Fungies</option>
              </select>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Default</p>

              <button
                class="w-[38px] h-[22px] rounded-full bg-[#9CA3AF] flex items-center"
              >
                <div
                  class="h-[18px] w-[18px] bg-white rounded-full ml-[2px]"
                ></div>
              </button>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Humidity>50 for 10 days</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Stop watering</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Weather can be dangerous for plants.</p>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <button>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 11.5L6.5 18.5"
                    stroke="#30A156"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.041 6.58278L14.7992 6.69567C12.7873 6.87857 11.7814 6.97002 11.5447 7.62388C11.3079 8.27774 12.0222 8.99198 13.4507 10.4205L14.5795 11.5493C16.008 12.9778 16.7223 13.6921 17.3761 13.4553C18.03 13.2186 18.1214 12.2127 18.3043 10.2008L18.4172 8.95902C18.5273 7.74766 18.5824 7.14198 18.2202 6.77979C17.858 6.4176 17.2523 6.47266 16.041 6.58278Z"
                    stroke="#30A156"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </td>
          </tr>

          <tr
            class="grid grid-cols-[18.5%_18.5%_20%_17%_20%_6%] font-medium text-[#191B20] text-base leading-6 bg-[#F5F6F8] text-center"
          >
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center border border-[#EAECF2]"
            >
              <select disabled>
                <option value="Fungies">Fungies</option>
              </select>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Default</p>

              <button
                class="w-[38px] h-[22px] rounded-full bg-[#30A156] flex items-center justify-end"
              >
                <div
                  class="h-[18px] w-[18px] bg-white rounded-full mr-[2px]"
                ></div>
              </button>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Humidity>50 for 10 days</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Stop watering</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Weather can be dangerous for plants.</p>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <button>
                <svg
                  width="63"
                  height="95"
                  viewBox="0 0 63 95"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="63" height="95" fill="#F5F6F8" />
                  <path
                    d="M37 42L29.5 49.5"
                    stroke="#F26B59"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M26.0447 52.4184L26.4166 47.5839C26.4494 47.158 26.9666 46.9666 27.2687 47.2687L31.7313 51.7313C32.0334 52.0334 31.842 52.5506 31.4161 52.5834L26.5816 52.9553C26.2759 52.9788 26.0212 52.7241 26.0447 52.4184Z"
                    stroke="#F26B59"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </td>
          </tr>
          <tr
            class="grid grid-cols-[18.5%_18.5%_20%_17%_20%_6%] font-medium text-[#191B20] text-base leading-6 bg-[#F5F6F8] text-center"
          >
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center border border-[#EAECF2]"
            >
              <select disabled>
                <option value="Fungies">Fungies</option>
              </select>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Default</p>

              <button
                class="w-[38px] h-[22px] rounded-full bg-[#30A156] flex items-center justify-end"
              >
                <div
                  class="h-[18px] w-[18px] bg-white rounded-full mr-[2px]"
                ></div>
              </button>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Humidity>50 for 10 days</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Stop watering</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Weather can be dangerous for plants.</p>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <button>
                <svg
                  width="63"
                  height="95"
                  viewBox="0 0 63 95"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="63" height="95" fill="#F5F6F8" />
                  <path
                    d="M37 42L29.5 49.5"
                    stroke="#F26B59"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M26.0447 52.4184L26.4166 47.5839C26.4494 47.158 26.9666 46.9666 27.2687 47.2687L31.7313 51.7313C32.0334 52.0334 31.842 52.5506 31.4161 52.5834L26.5816 52.9553C26.2759 52.9788 26.0212 52.7241 26.0447 52.4184Z"
                    stroke="#F26B59"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </td>
          </tr>

          <tr
            class="grid grid-cols-[18.5%_18.5%_20%_17%_20%_6%] font-medium text-[#191B20] text-base leading-6 bg-white text-center"
          >
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center border border-[#EAECF2]"
            >
              <select>
                <option value="Fungies">Fungies</option>
              </select>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Default</p>

              <button
                class="w-[38px] h-[22px] rounded-full bg-[#9CA3AF] flex items-center"
              >
                <div
                  class="h-[18px] w-[18px] bg-white rounded-full ml-[2px]"
                ></div>
              </button>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Humidity>50 for 10 days</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Stop watering</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Weather can be dangerous for plants.</p>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <button>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 11.5L6.5 18.5"
                    stroke="#30A156"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.041 6.58278L14.7992 6.69567C12.7873 6.87857 11.7814 6.97002 11.5447 7.62388C11.3079 8.27774 12.0222 8.99198 13.4507 10.4205L14.5795 11.5493C16.008 12.9778 16.7223 13.6921 17.3761 13.4553C18.03 13.2186 18.1214 12.2127 18.3043 10.2008L18.4172 8.95902C18.5273 7.74766 18.5824 7.14198 18.2202 6.77979C17.858 6.4176 17.2523 6.47266 16.041 6.58278Z"
                    stroke="#30A156"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </td>
          </tr>

          <tr
            class="grid grid-cols-[18.5%_18.5%_20%_17%_20%_6%] font-medium text-[#191B20] text-base leading-6 bg-white text-center"
          >
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center border border-[#EAECF2]"
            >
              <select>
                <option value="Fungies">Fungies</option>
              </select>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Default</p>

              <button
                class="w-[38px] h-[22px] rounded-full bg-[#9CA3AF] flex items-center"
              >
                <div
                  class="h-[18px] w-[18px] bg-white rounded-full ml-[2px]"
                ></div>
              </button>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Humidity>50 for 10 days</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Stop watering</p>
            </td>

            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <p>Weather can be dangerous for plants.</p>
            </td>
            <td
              class="px-[15px] py-[12px] h-[77px] flex items-center justify-center gap-x-[10px] border border-[#EAECF2]"
            >
              <button>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 11.5L6.5 18.5"
                    stroke="#30A156"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.041 6.58278L14.7992 6.69567C12.7873 6.87857 11.7814 6.97002 11.5447 7.62388C11.3079 8.27774 12.0222 8.99198 13.4507 10.4205L14.5795 11.5493C16.008 12.9778 16.7223 13.6921 17.3761 13.4553C18.03 13.2186 18.1214 12.2127 18.3043 10.2008L18.4172 8.95902C18.5273 7.74766 18.5824 7.14198 18.2202 6.77979C17.858 6.4176 17.2523 6.47266 16.041 6.58278Z"
                    stroke="#30A156"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- bottom buttons -->
  <!--<div class="sticky bottom-0 grid grid-cols-3 text-[18px] leading-[24px] font-normal text-center text-white">-->
  <vw-edit-cultivation-footer
    (save)="save($event)"
    (changeLanguage)="changeEditingLanguage($event)"
    (next)="gotToNextPage($event)"
  >
  </vw-edit-cultivation-footer>
</div>
