import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-home-users',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './home-users.component.html',
  styleUrls: ['./home-users.component.scss']
})
export class HomeUsersComponent {

}
