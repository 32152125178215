<div [class.has-success]="success" [class]="'form-group ' + formGroupClasses"
     [class.has-danger]=" error || (errors && errors[errorField]) || (format && value !== undefined && !format.test(value))">

  <div [ngClass]="{'flex': inline, 'items-center': inline, 'gap-x-1.5': inline}" >

    <label [class]="'text-[17px] font-medium left-7 ' + labelClasses"
           [for]="name" *ngIf="label && !onlyValue">
      {{(label) | translate}}

      <i class="fas fa-1x fa-info-circle text-primary" *ngIf="toolTip | translate"
         style="margin-left: 3px;" data-toggle="tooltip" title=""></i>
    </label>
    <ng-content select="[input-template]"></ng-content>
  </div>
  <!-- input [ngbTooltip]="toolTip | translate" -->

  <!-- success label -->
  <div class="valid-feedback" *ngIf="success && successLabel">
    {{(successLabel) | translate}}
  </div>

  <!-- error label -->
  <div class="invalid-feedback"
       *ngIf="error || (errors && errors[errorField]) || (format && value !== undefined && !format.test(value))">
    <ng-container *ngIf="errorLabel">
      {{(errorLabel) | translate}}
    </ng-container>
    <ul *ngIf="showDefaultErrorLabel && errors[errorField] && errors[errorField]['length'] > 0">
      <ng-container *ngFor="let errorType of errors[errorField]"
                    [ngSwitch]="errorType">
        <!-- specific label -->
        <!-- <ng-container *ngSwitchCase="'isEnum'">
          {{'COMMON.ERRORS.VALIDATION.ISENUM' | translate}}
        </ng-container> -->
        <!-- default label -->
        <li *ngSwitchDefault>
          {{('COMMON.ERRORS.VALIDATION.' + (errorType | uppercase)) | translate: {field: (errorFieldLabel || label) | translate} }}
        </li>
      </ng-container>
    </ul>
  </div>

</div>
