<section class="h-[calc(100vh-120px)] overflow-y-auto py-5 px-3">
    <vw-notifications-list></vw-notifications-list>
    <div class="flex p-5">
        <div class="w-2/3 px-3 py-6 mr-2 bg-[#FFFFFF] rounded-md">
            <vw-notification-creator></vw-notification-creator>
        </div>
        <div class="w-1/3">        
        </div>

    </div>
</section>
