<!-- content -->
<div class="py-[54px] px-10 h-[calc(100vh-77px-72px)] overflow-y-auto">
  <form class="space-y-[15px]" [formGroup]="form">
    <vw-edit-cultivation-header
      [label]="'CULTIVATION.GLOBAL_DATA.TITLE.' + mode"
    ></vw-edit-cultivation-header>
    <vw-bullet-label
      [number]="'1'"
      [label]="'CULTIVATION.GLOBAL_DATA.UPLOAD_PICTURE.LABEL'"
    >
      <vw-picture-uploader [backgroundColor]="cultivation.backgroundColor" [(file)]="cultivationCoverPicture"
      ></vw-picture-uploader>
    </vw-bullet-label>
    <vw-bullet-label
      [number]="'2'"
      [label]="'CULTIVATION.GLOBAL_DATA.COLOR.LABEL'"
    >
      <vw-color-picker
        formControlName="backgroundColorCtrl"
        (valueChange)="cultivation.backgroundColor = $event"
      ></vw-color-picker>
    </vw-bullet-label>

    <vw-bullet-label
      [number]="'3'"
      [label]="'CULTIVATION.GLOBAL_DATA.NAME.LABEL'"
    >
      <vw-text-input
        [placeholder]="'CULTIVATION.GLOBAL_DATA.NAME.PLACEHOLDER'"
        formControlName="nameCtrl"
      ></vw-text-input>
      <vw-text-area
        [placeholder]="'CULTIVATION.GLOBAL_DATA.DESCRIPTION.PLACEHOLDER'"
        formControlName="descriptionCtrl"
      ></vw-text-area>
    </vw-bullet-label>
  </form>
</div>

<!-- bottom buttons -->
<vw-edit-cultivation-footer
  (save)="save($event)"
  (changeLanguage)="changeEditingLanguage($event)"
  (next)="gotToNextPage($event)"
>
</vw-edit-cultivation-footer>
