import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-social-messages',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './social-messages.component.html',
  styleUrls: ['./social-messages.component.scss']
})
export class SocialMessagesComponent {

}
