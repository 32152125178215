<div class="bg-white rounded-[8px] shadow-[0px_3px_7px_0px_rgba(0,0,0,0.04)]
    min-w-[210px] border-[0.5px] border-[#E1E1E1] py-[6px] pl-[8px] pr-[14px] flex items-center gap-x-[10px]">

    <ng-content></ng-content>

    <button (click)="isOpen = !isOpen" class="bg-[rgba(0,0,0,0.08)] rounded-full pt-[3px] pr-0.5 pb-[3px] pl-0.5 flex items-center justify-center relative ml-[10px]">
        <div class="shrink-0 w-[22px] h-5 relative overflow-hidden">
            <vw-arrow-down-icon></vw-arrow-down-icon>
        </div>
    </button>
</div>

<ul class="list-group absolute" *ngIf="isOpen">
    <vw-text-input [label]="'Recherche'"></vw-text-input>
    <li *ngFor="let item of options; let index = index"
            [ngClass]="item.isActive ? 'activeOption' : ''"
            (click)="selectOption($event, index)"
            class="item list-group-item">
        THIS IS AN ITEM
    </li>
</ul>