import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {ArticleComponent} from './article/article.component';
import {NgForOf} from '@angular/common';
import {ArticleCreatorComponent} from './article-creator/article-creator.component';

@Component({
    selector: 'vw-social-home',
    templateUrl: './social-home.component.html',
    styleUrls: ['./social-home.component.scss'],
    standalone: true,
    imports: [FormsModule, ArticleComponent, NgForOf, ArticleCreatorComponent]
})
export class SocialHomeComponent implements OnInit {
  articles: Array<any> = [{}, {}, {}]
  constructor() { }

  ngOnInit(): void {
  }

}
