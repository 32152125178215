import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { CultivationDto } from "vegiwise-sdk-typescript/dist/model/@common/cultivation";
import { ArrowDownIconComponent } from "../../../../@commons/components/@generic/icons/arrow-down-icon/arrow-down-icon.component";
import { DropDownComponent } from "../../../../@commons/components/@generic/inputs/drop-down/drop-down.component";

@Component({
  selector: "vw-edit-cultivation-header",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ArrowDownIconComponent,
    DropDownComponent,
  ],
  templateUrl: "./edit-cultivation-header.component.html",
  styleUrls: ["./edit-cultivation-header.component.scss"],
})
export class EditCultivationHeaderComponent {
  @Input() label: string;
  @Input() description: string;
  @Input() selectedCultivation: CultivationDto;
  @Input() displayCultivationSelector: boolean = false;
}
