import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { TextInputComponent } from "../../@commons/components/@generic/inputs/text-input/text-input.component";
import { MailIconComponent } from "../../@commons/components/@generic/icons/mail-icon/mail-icon.component";
import { KeyIconComponent } from "../../@commons/components/@generic/icons/secret-icon/key-icon.component";
import { TranslateModule } from "@ngx-translate/core";
import { PersonStandingIconComponent } from "../../@commons/components/@generic/icons/person-standing-icon/person-standing-icon.component";
import { ButtonComponent } from "../../@commons/components/@generic/buttons/button/button.component";
import { PlusIconComponent } from "../../@commons/components/@generic/icons/plus-icon/plus-icon.component";
import { ErrorMessageComponent } from "../../@commons/components/@forms/error-message/error-message.component";
import { LinkComponent } from "../../@commons/components/@generic/link/link.component";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { OfficeIconComponent } from "../../@commons/components/@generic/icons/office-icon/office-icon.component";
import { UserApi } from "vegiwise-sdk-typescript/dist/sdk/user/user.api";
import { RegisterAdminRequest } from "vegiwise-sdk-typescript/dist/model/auth/register-admin.request";
import { Language } from "vegiwise-sdk-typescript/dist/enums/language.enum";
import { RegisterAdminResponse } from "vegiwise-sdk-typescript/dist/model/auth/register-admin.response";
import { StorageService } from "../../@commons/services/front/storage.service";

@Component({
  selector: "vw-login",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TextInputComponent,
    MailIconComponent,
    KeyIconComponent,
    TranslateModule,
    PersonStandingIconComponent,
    ButtonComponent,
    PlusIconComponent,
    ErrorMessageComponent,
    LinkComponent,
    OfficeIconComponent,
  ],
  templateUrl: "./confirm.component.html",
  providers: [UserApi],
  styleUrls: ["./confirm.component.scss"],
})
export class ConfirmComponent implements OnInit {
  errors: any[] = []; //TODO
  isConfirmed = false;
  public form: FormGroup;
  registerEmail: string;

  constructor(
    private route: ActivatedRoute,
    private userApi: UserApi,
    private storageService: StorageService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.initForm();
    this.registerEmail = this.storageService.getItem("REGISTER_EMAIL");

    this.route.queryParams.subscribe((params) => {
      if (params["email"]) {
        this.registerEmail = params["email"]; //when we will deliver mails with url inside ?
      }
    });
  }

  initForm() {
    this.form = this.fb.group({
      codeCtrl: new FormControl("", [Validators.required]),
    });
  }

  createAccount() {
    const code = this.form.controls.codeCtrl.value;
    this.userApi
      .confirm({
        username: this.registerEmail,
        code,
      })
      .then((res: RegisterAdminResponse) => {
        this.isConfirmed = true;
        setTimeout(() => {
          this.router.navigate(["/auth/login"]);
        }, 4500);
      });
  }
}
