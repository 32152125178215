<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
     xmlns="http://www.w3.org/2000/svg">
    <path
            d="M15 9L3.75 20.25"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
    />
    <path
            d="M5.99978 17.9999C1.49978 10.4999 7.49978 2.99994 20.2498 3.74994C20.9998 16.4999 13.4998 22.4999 5.99978 17.9999Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
    />
</svg>