<div
  *ngIf="isPendingAction"
  class="h-[280px] w-full rounded-[10px] overflow-hidden text-white"
>
  <div
    [style.background-image]="'url(' + cultivationPictureUrl + ')'"
    class="h-[220px] w-full px-2.5 py-3.5 bg-no-repeat bg-cover bg-center relative"
  >
    <ng-container *ngTemplateOutlet="cultivationBody"></ng-container>
  </div>
  <vw-cultivation-item-action
    *ngIf="isPendingAction"
  ></vw-cultivation-item-action>
  <!--<div class="bg-[#BE4A3A] h-full px-2.5 pt-1 pb-2.5" *ngIf="isPendingAction">
        <div class="flex items-center gap-x-1">
            <img src="./assets/common/medical-file.svg" alt="medical-file"/>
            <p class="text-[13px] leading-[21px] font-medium">Action Required!</p>
        </div>
        <p class="text-[11px] font-light leading-[13px]">Lorem ipsum dolor sit amet, consectetur adipisc</p>
    </div>-->
</div>
<div
  *ngIf="!isPendingAction"
  [style.background-color]="backgroundColor"
  [style.background-image]="cultivationPictureUrl"
  [class.bg-[#fff]]="!cultivationPictureUrl"
  class="h-[220px] w-full px-2.5 py-3.5 bg-no-repeat bg-cover bg-center relative rounded-[10px] overflow-hidden"
  [class]="textColor"
>
  <ng-container *ngTemplateOutlet="cultivationBody"></ng-container>
</div>

<ng-template #cultivationBody>
  <div class="h-[280px] w-full rounded-[10px] overflow-hidden">
    <div class="flex justify-between items-start">
      <div>
        <p class="font-bold text-[20px] leading-6">
          {{ cultivation?.name | Label }}
        </p>
        <p class="font-light text-xs leading-6">
          {{ cultivation?.family?.name | Label }}
        </p>
      </div>

      <div class="flex items-center gap-x-0.5">
        <p class="font-medium text-sm leading-6">5.0</p>

        <img src="/assets/common/star.svg" alt="star" />
      </div>
    </div>

    <div
      class="absolute bottom-3 flex items-center justify-between w-[calc(100%-20px)]"
    >
      <div class="flex gap-x-2.5">
        <div
          class="w-[40px] h-[40px] flex justify-center items-center relative"
        >
          <p class="absolute text-xs leading-6 font-medium">80%</p>
          <svg width="40" height="40" viewBox="0 0 40 40" class="-rotate-90">
            <circle
              cx="20"
              cy="20"
              r="18"
              fill="none"
              stroke="#FFFFFF35"
              stroke-width="4"
            />
            <circle
              class="percent fifty"
              cx="20"
              cy="20"
              r="18"
              fill="none"
              stroke="#FFFFFF"
              stroke-width="4"
              pathLength="100"
              stroke-dasharray="100"
              stroke-dashoffset="20"
            />
          </svg>
        </div>

        <div
          class="w-[40px] h-[40px] flex justify-center items-center relative"
        >
          <p class="absolute text-xs leading-6 font-medium">06</p>
          <svg width="40" height="40" viewBox="0 0 40 40" class="-rotate-90">
            <circle
              cx="20"
              cy="20"
              r="18"
              fill="none"
              stroke="#FFFFFF35"
              stroke-width="4"
            />
            <circle
              class="percent fifty"
              cx="20"
              cy="20"
              r="18"
              fill="none"
              stroke="#FFFFFF"
              stroke-width="4"
              pathLength="100"
              stroke-dasharray="100"
              stroke-dashoffset="60"
            />
          </svg>
        </div>
      </div>

      <div class="flex items-center gap-x-0.5">
        <img src="/assets/common/ArrowFatLinesUp.svg" alt="ArrowFatLinesUp" />
        <p class="text-xs leading-6 font-medium">12</p>
      </div>
    </div>
  </div>
</ng-template>
