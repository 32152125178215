import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "vw-footer-button",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./footer-button.component.html",
  styleUrls: ["./footer-button.component.scss"],
})
export class FooterButtonComponent {
  @Input() backgroundColor: string;
  @Input() label: string;
}
