import { Component, EventEmitter, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterButtonComponent } from "./footer-button/footer-button.component";
import { FatArrowIconComponent } from "../../../../@commons/components/@generic/icons/fat-arrow-icon/fat-arrow-icon.component";
import { RightLongArrowComponent } from "../../../../@commons/components/@generic/icons/right-long-arrow/right-long-arrow.component";
import { LanguageIconComponent } from "../../../../@commons/components/@generic/icons/language-icon/language-icon.component";
import { ArrowDownIconComponent } from "../../../../@commons/components/@generic/icons/arrow-down-icon/arrow-down-icon.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "vw-edit-cultivation-footer",
  standalone: true,
  imports: [
    CommonModule,
    FooterButtonComponent,
    FatArrowIconComponent,
    RightLongArrowComponent,
    LanguageIconComponent,
    ArrowDownIconComponent,
    TranslateModule,
  ],
  templateUrl: "./edit-cultivation-footer.component.html",
  styleUrls: ["./edit-cultivation-footer.component.scss"],
})
export class EditCultivationFooterComponent {
  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() next: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeLanguage: EventEmitter<string> = new EventEmitter();
  currentLanguage: string = "fr";
  availableLanguages: string[] = ["fr", "nl", "de", "en"];
  isLanguageSelectorOpen: boolean = false;

  onNext($event: MouseEvent) {
    this.next.emit();
  }

  onSave($event: MouseEvent) {
    this.save.emit();
  }

  onChangeLanguage(lang: string) {
    this.isLanguageSelectorOpen = !this.isLanguageSelectorOpen;
    this.currentLanguage = lang;
    this.changeLanguage.emit(this.currentLanguage);
  }
}
