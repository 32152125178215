<button
  [style.background-color]="backgroundColor"
  class="px-[24px] py-[24px] flex items-center justify-center gap-x-2 w-full"
>
  <span>
    <ng-content></ng-content>
  </span>
  <span>{{ label }}</span>
  <ng-content select="after-icon"></ng-content>
</button>
