<div class="relative">
  <!-- content -->
  <form [formGroup]="form">
    <div
      class="py-[54px] max-w-[700px] mx-auto h-[calc(100vh-77px-72px)] overflow-y-auto scrollBarHidden"
    >
      <vw-edit-cultivation-header
        [label]="'CULTIVATION.STEPS.TITLE'"
        [description]="'CULTIVATION.STEPS.DESCRIPTION'"
        [selectedCultivation]="cultivation"
      >
      </vw-edit-cultivation-header>
      <vw-button
        [look]="'SECONDARY'"
        (click)="addStep($event)"
        [buttonClasses]="'w-full mt-3 mb-3'"
        [label]="'CULTIVATION.STEPS.ADD'"
        [size]="'S'"
      >
        <vw-plus-icon class="btn-icon"></vw-plus-icon>
      </vw-button>

      <div class="space-y-[15px]" formArrayName="stepCtrls">
        <ng-container *ngFor="let stepCtrl of stepCtrls.controls; index as i">
          <div [formGroupName]="i">
            <vw-edit-growing-step (upClicked)="putGrowingStepUp(i)"
                                  (downClicked)="putGrowingStepDown(i)"
                                  (delete)="deleteStep(i)"
              [stepNumber]="i"
              [formGroup]="stepCtrl"
              [totalSteps]="stepCtrls.controls.length"
            ></vw-edit-growing-step>
          </div>
        </ng-container>
      </div>
    </div>
  </form>

  <!-- bottom buttons -->
  <vw-edit-cultivation-footer
    (save)="save($event)"
    (changeLanguage)="changeEditingLanguage($event)"
    (next)="gotToNextPage($event)"
  >
  </vw-edit-cultivation-footer>
</div>
