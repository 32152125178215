<button
  [style]="{
    'background-color': backgroundColor,
    'border-color': borderColor,
    height: height + 'px',
    width: width + 'px'
  }"
  [class]="(look === 'LIGHT' ? 'border' : '') + buttonClasses"
  class="flex justify-center items-center rounded-full"
>
  <ng-content select=".btn-icon"> </ng-content>

  <span [style.color]="textColor" class="text-center text-[17px]">
    {{ label | translate }}
  </span>
</button>
