import {Component, Input, Optional, Self} from '@angular/core';
import {CommonModule, NgTemplateOutlet} from '@angular/common';
import {BaseInput} from "../../../../abstract/base-input";
import {FormsModule, NgControl} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {InputComponent} from "../input/input.component";

@Component({
    selector: 'vw-text-area',
    standalone: true,
    imports: [CommonModule,
      FormsModule,
      TranslateModule,
      InputComponent,
      NgTemplateOutlet,
    ],
    templateUrl: './text-area.component.html',
    styleUrls: ['./text-area.component.scss']
})
export class TextareaComponent<T> extends BaseInput<T> {
    @Input() rows: number;

    constructor(
        @Self() @Optional() control: NgControl
    ) {
        super(control);
    }
}