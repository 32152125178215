<!--<vw-form-group
        [format]="format"
        [value]="value"
        [name]="name"
        [onlyValue]="onlyValue"
        [label]="label"
        [prefix]="prefix"
        [toolTip]="toolTip"
        [error]="error"
        [errors]="errors"
        [errorField]="errorField"
        [showDefaultErrorLabel]="showDefaultErrorLabel"
        [errorFieldLabel]="errorFieldLabel"
        [errorLabel]="errorLabel"
        [success]="success"
        [labelClasses]="labelClasses"
        [successLabel]="successLabel"
>


</vw-form-group>
-->

<!--[value]="(potentialValue !== null && potentialValue !== undefined && valueProperty ? potentialValue[valueProperty] : potentialValue)"-->
<!--[class.selected]="((value !== null && value !== undefined && valueProperty ? value[valueProperty] : value)) === ((potentialValue !== null && potentialValue !== undefined && valueProperty ? potentialValue[valueProperty] : potentialValue))"-->

<div class="flex items-center gap-x-2.5 mt-2.5">

    <vw-button *ngFor="let potentialValue of values" (click)="onSelect(((potentialValue !== null && potentialValue !== undefined && valueProperty ? potentialValue[valueProperty] : potentialValue)))"
               [look]="currentValue.includes(((potentialValue !== null && potentialValue !== undefined && valueProperty ? potentialValue[valueProperty] : potentialValue))) ? 'DEFAULT' : 'LIGHT'"
                [label]="((prefix ? prefix + '.' : '') + (displayProperty ? potentialValue[displayProperty] : potentialValue)) | translate">
    </vw-button>
</div>