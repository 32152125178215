<svg class=""
     style=""
     width="12"
     height="6"
     viewBox="0 0 12 6"
     fill="none"
     xmlns="http://www.w3.org/2000/svg" >
    <path d="M11.5 0.500039C11.5 0.500039 7.44931 5.49999 5.99996 5.5C4.5506 5.50001 0.5 0.5 0.5 0.5"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"  />
</svg>