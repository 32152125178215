<section class="h-[calc(100vh-120px)] overflow-y-auto py-5 px-3">
    <div class="flex">
        <vw-article-creator></vw-article-creator>
        <div class="w-1/2 m-5 px-3 py-6 bg-[#FFFFFF] rounded-md">
            <vw-article 
                [articles]="articles" 
                *ngFor="let article of articles"
            ></vw-article>
        </div>
    </div>
</section>
