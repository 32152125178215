import { Component, Input, TemplateRef } from '@angular/core';
import {AppHeaderComponent} from '../../components/app-header/app-header.component';
import {AppMenuComponent} from '../../components/app-menu/app-menu.component';
import {CommonModule} from '@angular/common';
import {AppEmulatorComponent} from '../../components/app-emulator/app-emulator.component';

@Component({
    selector: 'vw-default-layout',
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.scss'],
    imports: [
        AppHeaderComponent,
        AppMenuComponent,
        CommonModule,
        AppEmulatorComponent
    ],
    standalone: true
})
export class DefaultLayoutComponent  {
  @Input() headerTemplate: TemplateRef<any>;
  @Input() tabsTemplate: TemplateRef<any>;
  @Input() bodyTemplate: TemplateRef<any>;
  @Input() footerTemplate: TemplateRef<any>;
  @Input() headerAdditionalComponent: TemplateRef<any>;

  constructor(
  ) {
  }
}
