import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DefaultLayoutComponent } from '../@commons/layouts/default-layout/default-layout.component';
import { AppHeaderTabsComponent } from '../@commons/components/app-header-tabs/app-header-tabs.component';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import { HomePageComponent } from './home-page/home-page.component';
import {TabMenuItem} from '../@commons/classes/tab-menu-item';

@Component({
    selector: 'vw-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [RouterOutlet, HttpClientModule, CommonModule, AppHeaderTabsComponent, DefaultLayoutComponent, HomePageComponent],
})
export class HomeComponent implements OnInit {
  tabs: TabMenuItem[] = [{
    label: 'MENU.USERS.SUBMENU.LEADS',
        routerLink: 'leads'
      },{
    label: 'MENU.USERS.SUBMENU.PRODUCTS',
        routerLink: 'products'
      },{
    label: 'MENU.USERS.SUBMENU.USERS',
        routerLink: 'users'
      }];

  constructor() { }

  ngOnInit() {
  }

}
