<div class="p-5 space-y-[15px] mb-5 rounded-[8px] bg-white border-[0.5px] border-[#B4B9CC] text-[15px] leading-6"
     [formGroup]="formGroup">
    <div class="flex items-center justify-between">
        <div class="flex items-center gap-x-[15px]">
            <div class="w-[32px] h-[32px] flex items-center justify-center bg-[#FA9B1B] rounded-full">
                <vw-leaf-icon></vw-leaf-icon>
            </div>
            <p class="text-lg leading-6 font-medium">{{'CULTIVATION.PERIODS.ITEM.TITLE' | translate:  {
                periodNumber: periodNumber,
                totalPeriods: totalPeriods
            } }}</p>
            <vw-button-select [values]="this.cultivationPeriodTypes"
                              [prefix]="'CULTIVATION.PERIODS.TYPES'"
                              [formControlName]="'typeCtrl'">
            </vw-button-select>
        </div>
        <div class="flex gap-x-2.5 mt-2.5">
            <!--<div class="w-[32px] h-[32px] flex items-center justify-center bg-[#F0F2F5] rounded-full border border-[#002C661A]"></div>-->
            <vw-rounded-button [look]="'LIGHT'"></vw-rounded-button>
            <vw-button [look]="'ALERT'">
                <vw-trash-icon class="btn-icon" (click)="deletePeriod()"></vw-trash-icon>
            </vw-button>
        </div>
    </div>
    <div class="h-[0.5px] w-full bg-[#B4B9CC]"></div>
    <div class="space-y-[12px]">

        <!-- <vw-bullet-label [number]="'1'" [label]="'CULTIVATION.PERIODS.ITEM.TYPE.LABEL'">

         </vw-bullet-label>-->
        <vw-form-group [label]="'CULTIVATION.PERIODS.ITEM.PERIOD_LABEL'">
            <div input-template class="flex items-center gap-x-2.5 mt-2.5">
                <vw-select [values]="this.months" [formControlName]="'startDateCtrl'" [inline]="true"
                           [labelClasses]="'whitespace-nowrap'"
                           [placeholder]="''" [prefix]="'COMMON.MONTHS'"
                           [label]="'CULTIVATION.PERIODS.ITEM.FROM'"></vw-select>

                <vw-select [values]="this.months" [formControlName]="'endDateCtrl'" [inline]="true"
                           [labelClasses]="'whitespace-nowrap'"
                           [placeholder]="''" [prefix]="'COMMON.MONTHS'"
                           [label]="'CULTIVATION.PERIODS.ITEM.TO'"></vw-select>
            </div>
        </vw-form-group>

    </div>
</div>

<div class="h-[0.5px] w-full bg-[#ABB0C1] mt-5"></div>