import { Component, Optional, Self, Input, OnInit, ViewChild } from '@angular/core';
import {FormsModule, NgControl} from '@angular/forms';
import {BaseInput} from "../../../../abstract/base-input";
import {InputComponent} from "../input/input.component";
import {NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {FormGroupComponent} from "../form-group/form-group.component";

@Component({
  selector: 'vw-select',
  templateUrl: './select.component.html',
  standalone: true,
  imports: [
    InputComponent,
    NgTemplateOutlet,
    TranslateModule,
    FormsModule,
    FormGroupComponent,
    NgIf,
    NgForOf
  ],
  styleUrls: ['./select.component.scss']
})
export class SelectComponent<T> extends BaseInput<T> implements OnInit {
  @Input() values: any[];
  @Input() valueProperty: string;
  @Input() displayProperty: string;
  @ViewChild('select') select: any;
  isOpen = false;


  constructor(
    @Self() @Optional() control: NgControl
  ) {
    super(control);
  }

  ngOnInit() {
  }

  sendSelectChange(newValue): void {
    this.value = newValue;
    if (this.type === 'number') {
      if (this.value && this.value.length > 0) {
        const regNumber = new RegExp(`^[+-]?[0-9]+[0-9,\.]*$`);
        if (regNumber.test(this.value)) {
          this.value = this.value.replace(/\,/g, '.');
          this.value = parseFloat(this.value);
        }
      } else {
        this.value = null;
      }
    }
    this.valueChange.emit(this.value);
    this.onChange(this.value);
  }

  onSelectClick() {
    this.isOpen = !this.isOpen;
  }

  onBlur() {
    this.isOpen = false;
  }
}
