import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-fat-arrow-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './fat-arrow-icon.component.html',
  styleUrls: ['./fat-arrow-icon.component.scss']
})
export class FatArrowIconComponent {

}
