import {CultivationPeriodType} from "vegiwise-sdk-typescript/dist/enums/cultivation-period-type";
import {ExternalFileDto} from "vegiwise-sdk-typescript/dist/model/@common/external-file";

export class StringUtils {

    static isUUID(str: string): boolean {
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        // String with valid UUID separated by dash
        return regexExp.test(str); // true
    }
}