import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vw-app-emulator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-emulator.component.html',
  styleUrls: ['./app-emulator.component.scss']
})
export class AppEmulatorComponent {

}
